import React, { Component } from 'react';
import money from 'img/icons/money.svg';
import calendar from 'img/icons/calendar.svg';
import time from 'img/icons/time.svg';
import check from 'img/icons/check.svg';
import mark from 'img/icons/mark.svg';
import { forceOpenOverlay, objt } from 'utils';
import { withTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import { Helmet } from 'react-helmet';
import * as Cookies from 'js-cookie';
import { withRouter } from 'react-router-dom';

export class JobBox extends Component {

  constructor() {
    super();
    this.share = this.share.bind(this);
  }
  renderSalary () {
    const { job } = this.props;
    if (!job.salary) {
      return `${job.min_salary}$ - ${job.max_salary}$`;
    }
    return job.salary + '$';
  }

  renderJobTypes () {
    const { job } = this.props;
    if (job.job_types.length > 0 && job.job_types.length !== 1) {
      return job.job_types.reduce((previous, current) => {
        return objt(previous) + ', ' + objt(current);
      });
    } else if (job.job_types.length === 1) {
      return objt(job.job_types[0]);
    }
    return '';
  }

  renderAvailabilities () {
    const { job } = this.props;
    if (job.availabilities.length > 0 && job.availabilities.length !== 1) {
      return job.availabilities.reduce((previous, current) => {
        return objt(previous) + ', ' + objt(current);
      });
    } else if (job.availabilities.length === 1) {
      return objt(job.availabilities[0]);
    }
    return '';
  }

  getYoutubeLink () {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = this.props.job.youtube_link.match(regExp);
    return (match && match[7].length == 11) ? match[7] : false;
  }

  getMedia () {
    const { job } = this.props;
    if (job.preferred_media === 'image' && job.image !== null) {
      return <img src={(process.env.REACT_APP_STORAGE_URL + job.image.path).replace(/([^:]\/)\/+/g, '$1')} style={{ height: '500px', marginRight: 'auto', marginLeft: 'auto', marginBottom: 10 }} />;
    } else if (job.preferred_media === 'video' && job.youtube_link !== null) {
      return <iframe height="315" src={'https://www.youtube.com/embed/' + this.getYoutubeLink()} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen className="tw-mx-auto tw-mb-1 tw-w-full"></iframe>;
    }
  }

  share (link) {
    if (navigator.share) {
      navigator.share({
        url: link
      });
    } else {
      var dummy = document.createElement('textarea');
      document.body.appendChild(dummy);
      dummy.value = link;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
      alert(this.props.t('copiedLink'));
    }
  }


  render () {
    const { job, t } = this.props;
    return (
      <div className="col-lg-8 tw-h-64">
        {(this.props.loading === false)
          ? <div className="tw-card" style={{ borderTopColor: job.employer.color, borderTopWidth: '8px' }}>
            { this.getMedia() }
            {(job.image !== null) &&
            <Helmet>
              <title>AVA - { job.title }</title>
              <meta property="og:image" content={ process.env.REACT_APP_STORAGE_URL + job.image.path } />
              <script type="application/ld+json">
                {`
              {
                "@context" : "https://schema.org/",
                "@type" : "JobPosting",
                "title" : "${job.title}",
                "description" : "<p>${job.description}</p>",
                "datePosted" : "${job.created_at}",
                "applicantLocationRequirements": {
                  "@type": "Country",
                  "name": "Canada"
                },          
                "hiringOrganization" : {
                  "@type" : "Organization",
                  "name" : "${job.employer.name}",
                  "logo" : "${process.env.REACT_APP_STORAGE_URL + job.employer.logo}"
                },
                "jobLocation": {
                "@type": "Place",
                  "address": {
                  "@type": "PostalAddress",
                  "addressLocality": "${job.employer.city}",
                  "addressRegion": "QC",
                  "addressCountry": "CA"
                  }
                }
              }
            `}
              </script>

            </Helmet>}
            <p className="tw-font-bold tw-text-2xl">{job.title}</p>
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-flex-start md:tw-space-x-4 tw-my-1 tw-font-medium tw-gray-700 tw-space-y-2">
              <div className="tw-flex tw-items-center">
                <img src={money} /><span className="mt-1 tw-align-middle">{this.renderSalary()}</span>
              </div>
              <div className="tw-flex tw-items-center">
                <img src={calendar} /><span className="mt-1 tw-ml-2 tw-align-middle">{this.renderJobTypes()}</span>
              </div>
              <div className="tw-flex tw-items-center">
                <img src={time} /><span className="mt-1 tw-ml-2 tw-align-middle">{this.renderAvailabilities()}</span>
              </div>

            </div>
            {job.affiliation_link && <p>{this.props.t('linkToShareForAffiliation')}: <p onClick={this.share} className="tw-underline hover:tw-no-underline tw-text-aa-yellow tw-cursor-pointer">{job.affiliation_link}</p></p>}
            {(job.answer_sent && job.answer_sent == true)
              ? <div className="tw-flex tw-flex-col tw-items-center md:tw-space-y-0 tw-space-y-2 md:tw-flex-row tw-justify-between tw-text-semibold tw-text-white mt-2">
                <p>{this.props.t('answerSent')}</p>
                <div className="tw-flex tw-flex-row tw-bg-aa-yellow tw-p-3 tw-px-4 tw-space-x-4 tw-text-black  hover:tw-shadow-lg tw-cursor-pointer tw-justify-center md:tw-justify-end" onClick={() => { this.props.history.push('/job-offers'); }}>
                  <span>{this.props.t('seeOtherJobs')}</span>
                </div>
              </div>
              : <div className="tw-flex tw-flex-col-reverse md:tw-space-y-0 tw-space-y-2 md:tw-flex-row tw-justify-between tw-text-semibold tw-text-white my-2">
                <div className="tw-flex tw-flex-row tw-bg-red-600 tw-p-3 tw-px-4 tw-space-x-4  hover:tw-shadow-lg tw-cursor-pointer md:tw-justify-start tw-justify-center" onClick={() => { forceOpenOverlay() ? this.props.sendInterest(false) : Cookies.set('origin', this.props.location.pathname); }}>
                  <img src={mark} />
                  <span>{t('notInterested')}</span>
                </div>
                <div className="tw-flex tw-flex-row tw-bg-green-500 tw-p-3 tw-px-4 tw-space-x-4  hover:tw-shadow-lg tw-cursor-pointer tw-justify-center md:tw-justify-end" onClick={() => { forceOpenOverlay() ? this.props.sendInterest(true) : Cookies.set('origin', this.props.location.pathname); Cookies.set('job_offer', job.id); }}>
                  <span>{t('interested')}</span>
                  <img src={check} />
                </div>
              </div>
            }
            <p className="tw-whitespace-pre-line">{job.description}</p>
          </div>
          : <div className="tw-card tw-flex tw-flex-row tw-justify-center">
            <ReactLoading type="bubbles" color="#f5ff00" />
          </div>}
      </div>
    );
  }
}

export default withRouter(withTranslation('common')(JobBox));
