import axios from 'axios';
import * as Cookies from 'js-cookie';
import { getLanguage, dangerNotification } from 'utils';
import i18n from 'i18n';

var headers = {
  Accept: 'application/json',
  'Content-Type': 'multipart/form-data',
  enctype: 'multipart/form-data'
};

axios.defaults.headers = headers;

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

var params = {};

instance.interceptors.request.use(config => {
  config.params = {
    ...params,
    ...config.params
  };
  var headers = {};

  if (Cookies.get('auth')) {
    headers.Authorization = 'Bearer ' + Cookies.get('auth');
  }

  if (Cookies.get('employer')) {
    if(Cookies.get('employer') === Cookies.get('current')) {
      headers['X-Candidate-Type'] = 'employer';
    }
  }

  config.headers = {
    ...config.headers,
    ...headers,
    'Accept-Language': getLanguage()
  };
  return config;
});

instance.interceptors.response.use(res => {
  if (res.data.action) {
    switch (res.data.action) {
      case 'new-email':
        window.location.href = '/new-email?email=' + res.data.email;
        break;
      case 'password-setup':
        window.location.href = '/password-setup';
        break;
    }
  }
  return res;
}, error => {
  if(error !== undefined && error?.response !== undefined) {
    if (error.response.status === 422) {
      dangerNotification(i18n.t('common:fixErrors'));
    }
    if (error.response.status === 500) {
      dangerNotification(i18n.t('common:serverError'));
      setTimeout(() => {
        window.location.href = '/';
      }, 3000)
    }
    if (error.response.status === 404) {
      dangerNotification(i18n.t('common:notFound'));
      setTimeout(() => {
        window.location.href = '/';
      }, 3000)
    }

    if (error.response.data.message === 'Unauthenticated.') {
      if (!Cookies.get('auth')) {
        Cookies.remove('email_verified');
      } else {
        Cookies.remove('auth');
        Cookies.remove('email_verified');
        window.location.href = '/login';
      }
    }
    if (error.response.data.action) {
      switch (error.response.data.action) {
        case 'login':
          Cookies.set('origin', window.location.pathname, { expires: 1 / 48 });
          Cookies.remove('auth');
          Cookies.remove('email_verified');
          window.location.href = '/login';
          break;
        case 'verify-email':
          Cookies.set('origin', window.location.pathname, { expires: 1 / 48 });
          Cookies.set('email_verified', false);
          window.location.href = '/verify-email';
          break;
        case 'password-setup':
          window.location.href = '/password-setup?hash=' + error?.response?.data?.hash;
          break;    
      }
    }
  }
  return Promise.reject(error);
});

export default instance;
