import React, { Component } from 'react';
import logo from 'img/icons/home.svg';
import { withTranslation } from 'react-i18next';
import PanelContent from './PanelContent';
import Form from './Form';
import bg_signup from 'videos/bg-signup.mp4';
import { withRouter } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { utilsClosePopup } from 'utils';
import { BrowserView, MobileView } from 'react-device-detect';

export class Panel extends Component {

  getStep() {
    if(this.props.manualSteps) {
      if(this.props.manualSteps[this.props.current] == 'null') {
        return '';
      } else {
        return this.props.manualSteps[this.props.current];
      }
    }
    if(this.props.steps) {
      var values = Object.values(this.props.steps);
      return this.props.steps[this.props.current + 1] + ' / ' + values[values.length - 1]
    } else {
      return (this.props.current + 1) + ' / ' + (this.props.maxSlides - 1)
    }
  }

  render () {
    if(this.props?.popup == true) {
      return (
        <div className="tw-bg-white tw-text-gray-800 tw-text-center tw-p-2 tw-py-10 tw-relative" style={{maxHeight: '80vh', overflowY: 'auto'}}>
          <svg className="tw-cursor-pointer tw-absolute" style={{top: 10, right: 10}} onClick={utilsClosePopup} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>
          <div className="tw-container tw-mx-auto">
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
              <PanelContent {...this.props} /> 
              {this.props.onlyShow != true && <div class="tw-text-center">
              {this.getStep()}
              </div>}
            </div>
          </div>
        </div>
      )
  
    }
    return (
      <>
      <div className="bg-gray tw-fixed tw-inset-0 tw-min-h-full tw-min-w-full" />

      <BrowserView>
          <div className="bg-gray tw-fixed tw-inset-0 tw-min-h-full tw-min-w-full" />
          <video autoPlay muted loop className="tw-fixed tw-inset-0 tw-min-h-full tw-min-w-full" style={{maxWidth: 'none'}}>
            <source src={bg_signup} type="video/mp4" />
          </video>
      </BrowserView>
      <MobileView>
      <div className="bg-video tw-fixed tw-inset-0 tw-min-h-full tw-min-w-full" />
      </MobileView>
      <div className="tw-fixed tw-inset-0 tw-min-h-screen tw-w-full tw-text-center tw-p-8 tw-text-white tw-overflow-y-auto">
        <div className="tw-container tw-mx-auto">
          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
            <div className="tw-flex tw-flex-row tw-justify-between tw-w-full tw-items-center">
                <img src={logo} className="tw-h-12 tw-cursor-pointer" onClick={() => { this.props.history.push('/') }}></img>
                {this.props.location.pathname != '/cv' && <div className="tw-flex tw-flex-row tw-bg-aa-yellow tw-p-3 tw-px-4 tw-space-x-4 hover:tw-shadow-lg tw-cursor-pointer tw-justify-center md:tw-justify-end tw-text-black " onClick={() => { this.props.history.push(this.props.fillPage ? '/' : '/login') }}>

                  <span>{this.props.fillPage ? this.props.t('signup:backHome') : this.props.t('login')}</span>
                </div>}
            </div>
            <PanelContent {...this.props} />
            {this.props.onlyShow != true && <div class="tw-text-center">
              {this.getStep()}
              </div>}
          </div>
        </div>
      </div>
      </>
    )
  }
}

export default withRouter(withTranslation(['pages', 'signup'])(Panel));
