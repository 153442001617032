import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Screen from 'components/templates/Screen.js';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import Form from 'components/forms/Form';

export class Settings extends Component {
  render () {
    const { t } = this.props;
    return (
      <Screen type="triangle" title={this.props.t('pages:settings')}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 tw-h-64 tw-mx-auto">
              <div className="tw-card">
                <h2 className="tw-text-xl tw-font-bold">{t('language')}</h2>
                <Form name="language" classes="row" classesOverwrite={true}>
                  <div className="col-lg-6">
                    <input type="checkbox"
                      id="fr"
                      checked={window.localStorage.getItem('i18nextLng') === 'fr'}
                      onChange={() => {
                        this.props.i18n.changeLanguage('fr');
                        window.localStorage.setItem('i18nextLng', 'fr');
                      }
                      }
                    />
                    <label className="tw-ml-2" htmlFor="fr">{t('fr')}</label>
                  </div>
                  <div className="col-lg-6">
                    <input type="checkbox"
                      id="en"
                      checked={window.localStorage.getItem('i18nextLng') === 'en'}
                      onChange={() => {
                        this.props.i18n.changeLanguage('en');
                        window.localStorage.setItem('i18nextLng', 'en');
                      }}

                    />
                    <label className="tw-ml-2" htmlFor="en">{t('en')}</label>

                  </div>

                </Form>
              </div>

              <div className="tw-card tw-mt-3">
                <h2 className="tw-text-xl tw-font-bold">{t('pages:terms')}</h2>
                <Link to="/terms" className="tw-text-blue-700 tw-underline">{t('readTerms')}</Link>
              </div>
            </div>
          </div>
        </div>
      </Screen>
    );
  }
}

Settings.propTypes = {
  t: PropTypes.func.isRequired
};

export default withRouter(withTranslation('common')(Settings));
