import React from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from 'components/common/Button';

function PicturesUploadModal (props) {
  const { t } = useTranslation('common');

  return (
    <div className="col-lg-12 tw-mb-3">
      <div className="tw-card">
        <p>{ t('numberPics') } {30 - props.images.length}</p>
        <input type="file" onChange={props.handleChange} multiple />
        {(props.error === true) && <div className="tw-bg-red-100 tw-mt-2 tw-border-l-4 tw-border-red-500 tw-text-red-700 tw-p-2 tw-text-sm">
          <p>{t('filesErrors')}</p>
        </div> }
        <div className="tw-flex tw-flex-row tw-items-center">
          <Button text={t('sendSave')} action={props.handleSubmit} />
        </div>
      </div>
    </div>
  );
}

export default PicturesUploadModal;
