import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

export class Submit extends Component {
  render () {
    return (
      <input type="submit" onClick={this.props.action} value={this.props.title || this.props.t('common:submit')} className="focus:tw-bg-yellow-700 tw-bg-aa-yellow tw-px-3 tw-py-3 tw-font-bold  tw-text-sm tw-m-1 tw-self-end tw-leading-none tw-min-w-32 tw-text-black tw-cursor-pointer focus:tw-outline-none focus:tw-shadow-outline" />
    );
  }
}

Submit.propTypes = {
  title: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(Submit);
