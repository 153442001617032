import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { handleChange } from 'redux/actions';

export class YesNoInputWithContext extends Component {
  constructor (props) {
    super(props);
    this.state = { value: this.getValue(this.props.context) || '' };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  getRequired () {
    if (this.props.required === true) {
      return <span className="tw-font-semibold tw-text-aa-yellow tw-text-sm tw-ml-2">{this.props.t('required')}</span>;
    }
  }

  handleChange (e) {
    var value = e.target.value;
    this.props.handleChange(this.props.name, value, this.props.context.name);
  }

  handleBlur () {
  }

  getValue () {
    if (this.props.input && this.props.input[this.props.context.name] && this.props.input[this.props.context.name][this.props.name]) {
      return this.props.input[this.props.context.name][this.props.name];
    } else {
      return undefined;
    }
  }

  getTextClasses() {
    var base = "tw-ml-3 tw-block tw-text-sm tw-font-medium ";
    if(this.props.whiteText == true) {
      return base + " tw-text-white";
    } else {
      return base + " tw-text-gray-700";
    }
  }
  
  render () {
    return (
      <div className={`tw-flex tw-flex-col ${this.props.size} tw-mb-3 ` + (!this.props.noLabel && 'px-2')}>
        {!this.props.noLabel && <label className="tw-text-sm tw-font-bold">{this.props.title || this.props.t('common:' + this.props.name)}{this.getRequired()}</label>}
        <div className="tw-flex tw-items-center">
          <input onChange={this.handleChange} defaultChecked={this.getValue() == 1} id={'yes-' + this.props.name} value="1" name={this.props.name} type="radio" className="tw-bg-gray-300 focus:tw-ring-aa-yellow tw-h-4 tw-w-4 tw-text-aa-yellow tw-border-gray-300" />
          <label htmlFor={'yes-' + this.props.name} className={this.getTextClasses()}>
            {this.props.disableNo ? this.props.t('common:' + this.props.name) : this.props.t('common:yes')}
          </label>
        </div>
        {this.props.disableNo == false &&
        <div className="tw-flex tw-items-center">
          <input onChange={this.handleChange} defaultChecked={this.getValue() === 0} id={'no-' + this.props.name} value="0" name={this.props.name} type="radio" className="tw-bg-gray-300 focus:tw-ring-aa-yellow tw-h-4 tw-w-4 tw-text-aa-yellow tw-border-gray-300" />
          <label htmlFor={'no-' + this.props.name} className={this.getTextClasses()}>
            {this.props.t('common:no')}
          </label>
        </div>}
        {this.props.errors && this.props.errors[this.props.context.name] && this.props.errors[this.props.context.name][this.props.name] && (
          <div className="tw-bg-red-100 tw-mt-2 tw-border-l-4 tw-border-red-500 tw-text-red-700 tw-p-2 tw-text-sm">
            <p>{this.props.errors[this.props.context.name][this.props.name]}</p>
          </div>
        )}
      </div>
    );
  }
}

YesNoInputWithContext.defaultProps = {
  size: 'w-full',
  required: true,
  disableNo: false
};

YesNoInputWithContext.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  required: PropTypes.bool,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ errors, input }) => {
  return {
    errors: errors,
    input: input
  };
};

export default connect(mapStateToProps, { handleChange })(withTranslation(['input', 'common'])(YesNoInputWithContext));
