import React, { Component } from 'react';
import Button from 'components/common/Button';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

export class Pagination extends Component {
  getNumberPages () {
    return Math.ceil(this.props.count / 8);
  }

  getPage () {
    return this.props.page;
  }

  render () {
    return (
      <div className="tw-w-full tw-flex-row tw-flex tw-justify-end tw-space-x-2">
        {(this.getPage() > 1) &&
      <Button action={() => {
        this.props.history.push(this.props.location.pathname + '?page=' + (parseInt(this.getPage()) - 1));
        window.location.reload();
      }} text={this.props.t('previous')}></Button>}
        {(this.getPage() < this.getNumberPages()) &&
      <Button action={() => {
        this.props.history.push(this.props.location.pathname + '?page=' + (parseInt(this.getPage()) + 1));
        window.location.reload();
      }} text={this.props.t('next')}></Button>}
      </div>
    );
  }
}

export default withRouter(withTranslation('common')(Pagination));
