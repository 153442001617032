import React, { Component } from 'react';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { Helmet } from 'react-helmet';

export class ProductMedias extends Component {
  constructor (props) {
    super(props);
    this.state = {
      value: 0
    };
    this.onChange = this.onChange.bind(this);
  }

  getYoutubeLink () {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = this.props.product.youtube_link.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
  }

  getYoutubeVideo () {
    if (this.props.product.youtube_link) {
      return <iframe width="560" height="315" src={'https://www.youtube.com/embed/' + this.getYoutubeLink()} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>;
    }
    return undefined;
  }

  getImagesArray () {
    var images = this.props.images;
    var thumbnailId = this.props.product.thumbnail_id;
    var position;
    if (thumbnailId !== null) {
      for (var i = 0; i < images.length; i += 1) {
        if (images[i].id === thumbnailId) {
          position = i;
        }
      }
    }

    if (position !== undefined) {
      var thumbnail = images.splice(position, 1);
      images.unshift(thumbnail[0]);
    }
    return images;
  }

  getImages () {
    var images = this.getImagesArray();

    return (images || []).map(image => (
      <img key={image.id} src={ (process.env.REACT_APP_STORAGE_URL + image.path).replace(/([^:]\/)\/+/g, '$1')} />
    ));
  }

  getSlides () {
    if (this.getYoutubeVideo() !== undefined) {
      return [this.getYoutubeVideo()].concat(this.getImages());
    }
    return this.getImages();
  }

  onChange (value) {
    this.setState({ value });
  }

  render () {
    if (this.props.images.length !== 0 || this.getYoutubeVideo() !== undefined) {
      return (
        <div>
          {(this.props.images.length !== 0 ? <Helmet>
            <title>{ this.props.product.title } - Commerces d'ICI</title>
            <meta property="og:image" content={ process.env.REACT_APP_STORAGE_URL + this.getImagesArray()[0].path } />
          </Helmet> : null)}
          <Carousel
            value={this.state.value}
            onChange={this.onChange}
            arrows={true}
            dots={true}
            slides={
              this.getSlides()
            }
          >
          </Carousel>
          <Dots
            value={this.state.value}
            onChange={this.onChange}
          />
        </div>
      );
    }
    return null;
  }
}

export default ProductMedias;
