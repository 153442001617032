import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { handleChange } from 'redux/actions';
import { connect } from 'react-redux';
import FormContext from 'context/FormContext';
import ListInputItem from './ListInputItem';

export class ListInput extends Component {
  getRequired () {
    if (this.props.required === true) {
      return <span className="tw-font-semibold tw-text-aa-yellow tw-text-sm tw-ml-2">{this.props.t('required')}</span>;
    }
  }

  getValue (context) {
    if (this.props.input && this.props.input[context.name] && this.props.input[context.name][this.props.name]) {
      return this.props.input[context.name][this.props.name];
    } else {
      return undefined;
    }
  }

  render () {
    return (
      <FormContext.Consumer>
        {context =>
          <div className={`tw-flex tw-flex-col ${this.props.size} tw-mb-3` + (!this.props.noLabel && ' tw-px-2')}>
             {!this.props.noLabel && <label htmlFor={this.props.name} className="tw-text-sm tw-font-bold">{this.props.title || this.props.t('common:' + this.props.name)}{this.getRequired()}</label>}
            <div className="tw-text-black tw-flex tw-flex-col tw-w-full">
              <ListInputItem name={this.props.name} type="add" value={(this.props.input && this.props.input[context.name] && this.props.input[context.name][this.props.name + '_current']) || ''}/>
              {((this.props.input && this.props.input[context.name] && this.props.input[context.name][this.props.name]) || []).map(i => {
                return <ListInputItem name={this.props.name} key={i.id} type="existing" item={i} />;
              })}
            </div>

            {this.props.errors && this.props.errors[context.name] && this.props.errors[context.name][this.props.name] && (
              <div className="tw-bg-red-100 tw-mt-2 tw-border-l-4 tw-border-red-500 tw-text-red-700 tw-p-2 tw-text-sm">
                <p>{this.props.errors[context.name][this.props.name]}</p>
              </div>
            )}
          </div>
        }
      </FormContext.Consumer>

    );
  }
}

ListInput.defaultProps = {
  size: 'w-full',
  required: true,
  type: 'text'
};

ListInput.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  size: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ errors, input }, ownProps) => {
  return {
    errors: errors,
    input: input
  };
};

export default connect(mapStateToProps, { handleChange })(withTranslation(['input', 'common'])(ListInput));
