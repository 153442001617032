import React, { Component } from 'react';
import Screen from 'components/templates/Screen';
import { withTranslation } from 'react-i18next';
import emailSent from 'img/emailSent.svg';
import queryString from 'query-string';

export class NewEmailNotification extends Component {
  componentDidMount () {
  }

  render () {
    const params = queryString.parse(this.props.location.search);
    return (
      <Screen type="triangle" containerClasses="tw-h-full tw-flex tw-flex-row tw-justify-center tw-items-center" title={this.props.t('pages:verifyNewEmail')}>
        <div style={{ width: '500px', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} className="tw-flex tw-flex-col tw-justify-center tw-space-y-4 tw-items-center tw-text-center tw-card tw-p-4">
          <img style={{ height: '200px' }} src={emailSent} />
          <p>{this.props.t('verifyNewEmail', { email: params.email })}</p>
          <p>{this.props.t('verifyNewEmailOthers')}</p>
        </div>
      </Screen>
    );
  }
}

export default (withTranslation(['common', 'pages'])(NewEmailNotification));
