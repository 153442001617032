import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { handleChange } from 'redux/actions';

export class TextareaInputWithContext extends Component {
  constructor (props) {
    super(props);
    this.state = { value: this.getValue(this.props.context) || '' };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  getRequired () {
    if (this.props.required === true) {
      return <span className="tw-font-semibold tw-text-aa-yellow tw-text-sm tw-ml-2">{this.props.t('required')}</span>;
    }
  }

  handleChange (e) {
    var value = e.target.value;
    this.setState({ value: value });
  }

  handleBlur () {
    this.props.handleChange(this.props.name, this.state.value, this.props.context.name);
  }

  getValue () {
    if (this.props.input && this.props.input[this.props.context.name] && this.props.input[this.props.context.name][this.props.name]) {
      return this.props.input[this.props.context.name][this.props.name];
    } else {
      return undefined;
    }
  }

  render () {
    return (
      <div className={`tw-flex tw-flex-col ${this.props.size} tw-px-2 tw-mb-3`}>
        <label htmlFor={this.props.name} className="tw-text-sm tw-font-bold">{this.props.title || this.props.t('common:' + this.props.name)}{this.getRequired()}</label>
        <textarea
          onChange={this.handleChange}
          onBlur={() => {
            this.handleBlur();
          }}
          id={this.props.name} placeholder={this.props.title} className="tw-text-black focus:tw-shadow-outline tw-bg-gray-300  focus:tw-outline-none tw-py-2 px-3 tw-h-24">{this.state.value}</textarea>
        {this.props.errors && this.props.errors[this.props.context.name] && this.props.errors[this.props.context.name][this.props.name] && (
          <div className="tw-bg-red-100 tw-mt-2 tw-border-l-4 tw-border-red-500 tw-text-red-700 tw-p-2 tw-text-sm">
            <p>{this.props.errors[this.props.context.name][this.props.name]}</p>
          </div>
        )}
      </div>
    );
  }
}

TextareaInputWithContext.defaultProps = {
  size: 'w-full',
  required: true
};

TextareaInputWithContext.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  required: PropTypes.bool,
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ errors, input }) => {
  return {
    errors: errors,
    input: input
  };
};

export default connect(mapStateToProps, { handleChange })(withTranslation(['input'])(TextareaInputWithContext));
