import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import signup from 'videos/signup.mp4';

export class FormTemplate extends Component {
  render () {
    return (
      <div className="tw-min-h-screen tw-min-w-screen tw-flex tw-flex-col lg:tw-flex-row">
        <div className="tw-flex-1 lg:tw-h-screen tw-bg-blue-200 bg-triangle tw-flex tw-flex-row tw-justify-center tw-items-center">
          <video src={signup} className="tw-w-screen sm:tw-w-3/4" autoPlay controls />
        </div>
        <div style={{ backgroundColor: '#434349' }} className="tw-text-white tw-w-full lg:tw-w-form tw-h-screen tw-overflow-y-auto tw-overflow-x-none tw-px-8 tw-pt-4">
          <h1 className="tw-text-xl tw-font-bold tw-text-center tw-mb-4">{this.props.title}</h1>
          {this.props.loading === true
            ? <div className="tw-w-full tw-h-full tw-flex tw-flex-row tw-justify-center tw-items-center">
              <ReactLoading type="bubbles" color="#f5ff00" />
            </div>
            : this.props.children }
        </div>
      </div>
    );
  }
}

FormTemplate.defaultProps = {
  loading: false
};

FormTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
  loading: PropTypes.bool
};

export default FormTemplate;
