import React, { Component } from 'react';
import location from 'img/icons/location.svg';
import ReactLoading from 'react-loading';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

export class CompanyFooter extends Component {
  getSalesProfile () {
    const { employer } = this.props;
    if (employer.refunds !== undefined && employer.delivery_pickup_conditions !== undefined) {
      return <a href="" className="tw-underline tw-blue-700" onClick={() => this.props.history.push('/policy/' + employer.id)}>{this.props.t('policies')}</a>;
    }
  }

  render () {
    return (
      <div className="col-lg-12">
        <div className="bg-fullgray tw-items-center tw-text-center tw-flex tw-flex-col tw-my-4 tw-py-3">
          {this.getSalesProfile()}
        </div>
      </div>

    );
  }
}

export default withRouter(withTranslation('common')(CompanyFooter));
