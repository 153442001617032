import React, { Component } from 'react';
import Screen from 'components/templates/Screen';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Direction from 'components/homepage/Direction';
import { isAuth } from 'utils';

export class Homepage extends Component {
  constructor () {
    super();
    this.getDirections = this.getDirections.bind(this);
  }

  getDirections () {
    const { t } = this.props;
    if (isAuth()) {
      return (
        <>
          <Direction to="/profile" title={t('pages:myProfile')} side="r" />
          <Direction to="/job-offers" title={t('pages:jobs')} side="l" />
          <Direction to="/coffee-machine" title={t('pages:coffeeMachine')} side="r" />
          <Direction to="/shop" title={t('pages:shop')} side="l" />
          <Direction to="/logout" title={t('pages:logout')} side="l" />
        </>)
      ;
    } else {
      return (
        <>
          <Direction to="/login" title={t('pages:login')} side="l" />
          <Direction to="/signup" title={t('pages:sendApplication')} side="r" />
          <Direction to="/shop" title={t('pages:shop')} side="l" />
          <Direction to="https://employers.alpeconception.com/employers/login" title={t('pages:companies')} side="l" />
        </>
      );
    }
  }

  render () {
    return (
      <Screen type='homepage' containerClasses="tw-h-full tw-flex tw-flex-row tw-justify-start tw-items-center" title={this.props.t('pages:virtualOffice')}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto tw-space-y-4">
              {this.getDirections()}
            </div>
          </div>
        </div>
      </Screen>
    );
  }
}

export default withRouter(withTranslation(['common', 'pages'])(Homepage));
