import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class SecondaryButton extends Component {
  render () {
    return (
      <button onClick={this.props.action} className="focus:tw-bg-black focus:tw-text-white tw-border-2 tw-border-black tw-bg-white tw-px-3 tw-py-2 tw-font-bold  tw-text-sm tw-m-1 tw-self-end tw-leading-none tw-min-w-32 tw-text-black tw-cursor-pointer tw-flex tw-flex-row focus:tw-outline-none ">

        {this.props.icon && <img src={this.props.icon || ''} alt="Icon" className="tw-h-4 tw-mr-1" />}
        {this.props.text}
      </button>
    );
  }
}

SecondaryButton.defaultProps = {
  action: () => {},
  text: 'Default Text'
};

SecondaryButton.propTypes = {
  action: PropTypes.func,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
};

export default SecondaryButton;
