import {
  Form
} from 'components/forms/Form';
import Panel from 'components/forms/Panel';
import React, {
  Component
} from 'react';
import {
  withTranslation
} from 'react-i18next';
import instance from 'axiosInstance';
import * as Cookies from 'js-cookie';
import {
  formToFormData,
  encodeDataToURL,
  findIndex,
  utilsOpenPopup,
  utilsClosePopup,
  utilsSetStep,
  successNotification
} from 'utils';
import {
  withRouter
} from 'react-router-dom';
import {
  connect
} from 'react-redux';
import {
  addErrors
} from 'redux/actions';
import ReactLoading from 'react-loading';
import { Helmet } from 'react-helmet';

export class SignupCVVideo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPanel: 0,
      data: [],
      loading: true
    };
    this.manualSteps =  ['null', 'null', '1 / 6', '2 / 6', '3 / 6', '4 / 6', '5 / 6', '6 / 6'];
    this.manualPrevious =  ['null', '0', '0', '2', '3', '4', '5', '6', '7'];
    this.manualNext =  ['null', '8', '3', '4', '5', '6', '7', '8'];
    this.panels = [
      {
        'name': 'video_cv',
        'type': 'videoCvChoice',
        'noNext': true
      },
      {
        'name': 'video_cv_upload',
        'type': 'descriptionFile',
        'accepted': 'avi,mp4,mov,qt'
      },
      {
        'name': 'first_question',
        'type': 'recording'
      },
      {
        'name': 'second_question',
        'type': 'recording'
      },
      {
        'name': 'third_question',
        'type': 'recording'
      },
      {
        'name': 'fourth_question',
        'type': 'recording'
      },
      {
        'name': 'fifth_question',
        'type': 'recording'
      },
      {name: 'submit', type: 'submit'}
    ];
    this.nextSlide = this.nextSlide.bind(this);
    this.previousSlide = this.previousSlide.bind(this);
    this.setSlide = this.setSlide.bind(this);
    this.setPath = this.setPath.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    this.setState({
      loading: true
    });
    if (this.props.form) {
      var fd = formToFormData([
        'video_cv',
        'first_question',
        'second_question',
        'third_question',
        'fourth_question',
        'fifth_question'
    ], this.props.form);
      instance.post('/candidate/update-video', fd).then((success) => {
        successNotification(this.props.t('common:modificationsSaved'))

        if(success.data.profileComplete) {
          if (Cookies.get('popup-origin') !== undefined) {
            var origin = Cookies.get('popup-origin');
            Cookies.remove('popup-origin');
            this.props.history.push(origin);
          } else {
              this.props.history.push('/');
              utilsOpenPopup('explanations');
          }  
        } else {
          this.props.history.push('/cv')
        }

      }, (error) => {
        this.checkErrorsStage(error.response.data.errors);
        this.props.addErrors(error.response.data.errors, 'signup');
      }).then(() => {
        this.setState({
          loading: false
        });
      });
    } else {
      this.setState({
        loading: false,
        currentPanel: 0
      });
    }
  }

  checkErrorsStage(errors) {
    var smallest = 999999;
    if (typeof errors === 'object') {
      Object.keys(errors).forEach(error => {
        var index = findIndex(this.panels, error);
        if (index < smallest) {
          smallest = index;
        }
      });
    }
    if (smallest !== 999999) {
      this.setState({
        currentPanel: smallest
      });
    }
  }

  componentDidMount() {
    instance.get('/candidate-data')
      .then((success) => {
        this.setState({
          loading: false,
          data: {
            // interests: success.data.interests,
            // fieldsOfWork: success.data.fieldsOfWork,
            // salary: success.data.salary,
            // availability: success.data.availability,
            // job_type: success.data.job_type,
            // trainings: success.data.trainings,
            type: success.data.type
          }
        });
      });
  }

  nextSlide() {
    if(this.manualNext) {
      this.setState({
        currentPanel: this.manualNext[this.state.currentPanel]
      })
    } else {
      this.setState({
        currentPanel: this.state.currentPanel + 1
      });
  
    }
  }

  previousSlide() {
    if(this.manualPrevious) {
      this.setState({
        currentPanel: this.manualPrevious[this.state.currentPanel]
      })
    } else {
      this.setState({
        currentPanel: this.state.currentPanel - 1
      });
  
    }
  }

  setSlide(slide) {
    this.setState({
      currentPanel: slide
    });
  }


  setPath(path) {
    this.setState({
      path: path,
      currentPanel: this.state.currentPanel + 1
    });

  }

  render() {
    if (this.state.loading && this.state.loading === true) {
      return ( <div className = "tw-w-full tw-h-screen tw-flex tw-flex-row tw-justify-center tw-items-center"
        style = {
          {
            backgroundColor: '#434349'
          }
        } >
        <ReactLoading type = "bubbles"
        color = "#f5ff00" / >
        </div>
      );
    }
    return ( 
      <>
      <Form name = "signup"
      classes = ""
      classesOverwrite = {
        true
      }
      onSubmit = {
        (e) => {
          e.preventDefault()
        }
      } >
      <Panel submit = {
        this.handleSubmit
      }
      data = {
        this.state.data
      }
      setPath = {
        this.setPath
      }
      path = {
        this.state.path
      }
      nextSlide = {
        this.nextSlide
      }
      previousSlide = {
        this.previousSlide
      }
      setSlide = {
        this.setSlide
      }
      current = {
        this.state.currentPanel
      }
      maxSlides = {
        this.panels.length
      }
      panel = {
        this.panels[this.state.currentPanel]
      }
      manualSteps={this.manualSteps}
      type = "signup" 
      fillPage={true}
      />
      </Form>
      <Helmet>
        <title>AVA - {this.props.t('common:video_cv')}</title>
      </Helmet>

      </>
    )
  }

}


const mapStateToProps = ({
  input
}) => {
  return {
    form: input.signup
  };
};


export default withRouter(connect(mapStateToProps, {
  addErrors
})(withTranslation(['pages', 'common'])(SignupCVVideo)));
