import React, { Component } from 'react';
import Screen from 'components/templates/Screen';
import { withTranslation } from 'react-i18next';
import comingSoon from 'img/comingSoon.svg';

export class ComingSoon extends Component {
  render () {
    return (
      <Screen containerClasses="tw-h-full tw-flex tw-flex-row tw-justify-center tw-items-center" title={this.props.t('pages:comingSoon')}>
        <div style={{ width: '500px', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} className="tw-flex tw-flex-col tw-justify-center tw-space-y-4 tw-items-center tw-text-center tw-card tw-p-4">
          <img style={{ height: '200px' }} src={comingSoon} />
          <p>{this.props.t('comingSoonText')}</p>
          <div onClick={() => { this.props.history.push('/'); }} className="tw-border-aa-yellow tw-border-4 tw-p-3 tw-py-1  tw-font-bold hover:tw-bg-aa-yellow tw-cursor-pointer">
            <p>{this.props.t('common:backToHome')}</p>
          </div>
        </div>
      </Screen>
    );
  }
}

export default (withTranslation(['common', 'pages'])(ComingSoon));
