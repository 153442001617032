import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ProductMedias from './ProductMedias';
import cart from 'img/icons/cart-black.svg';
import { addToCart, changeMode, getMode, getQuantityFromCart, getAmountInCart } from './../../utils';

export class ProductBox extends Component {
  getYoutubeLink () {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = this.props.job.youtube_link.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
  }

  getAcquisitionMethods () {
    var methods = [];
    if (this.props.product.delivery === 'true') {
      methods.push(this.props.t('delivery'));
    }
    if (this.props.product.pickup === 'true') {
      methods.push(this.props.t('pickup'));
    }
    return methods.join(', ');
  }

  getCart () {
    if (getQuantityFromCart(this.props.product.id) > 0) {
      return (<div className="tw-mt-2 tw-text-white tw-px-6 tw-py-4 tw-border-0  tw-relative tw-mb-4 tw-bg-green-500">
        <span className="tw-inline-block tw-align-middle tw-mr-8">
          { this.props.t('quantityInCart', { quantity: getQuantityFromCart(this.props.product.id) })}
        </span>
        {this.getModeChange()}
      </div>);
    }
    return null;
  }

  getDeliveryFee () {
    const { product } = this.props;
    if (product.delivery_fees && product.delivery_fees != 0) {
      return <span>{this.props.t('deliveryFees')}: {product.delivery_fees}$ / {this.props.t('product')}</span>;
    } else {
      return <span>{this.props.t('noDeliveryFees')}</span>;
    }
  }

  getModeChange () {
    const { product } = this.props;
    return (
      <div>
        <div className="tw-flex tw-flex-row">
          <span className="tw-font-bold">{this.props.t('acquisition-method')}:</span>
          <div>
            {product.delivery === 'true' &&
            <label className="tw-inline-flex tw-items-center tw-ml-6">
              <input defaultChecked={getMode(product.id) === 'delivery'} onChange={() => { changeMode(product.id, 'delivery'); this.forceUpdate(); }} type="radio" name="mode" value="delivery"></input>
              <span className="tw-ml-1">{ this.props.t('delivery') }</span>
            </label>}
            {product.pickup === 'true' &&
            <label className="tw-inline-flex tw-items-center tw-ml-6">
              <input defaultChecked={getMode(product.id) === 'pickup'} onChange={() => { changeMode(product.id, 'pickup'); this.forceUpdate(); }} type="radio" name="mode" value="pickup"></input>
              <span className="tw-ml-1">{ this.props.t('pickup') }</span>
            </label>}
          </div>
        </div>
        <p>{getMode(product.id) === 'delivery' && this.getDeliveryFee()}</p>
      </div>
    );
  }

  addToCart (e) {
    const { product } = this.props;
    addToCart(product.id);
    if (getMode(product.id) === null) {
      changeMode(product.id, this.getDefaultMode());
    }
    this.forceUpdate();
    this.props.cartUpdate(getAmountInCart());
    e.target.blur();
  }

  getDefaultMode () {
    const { product } = this.props;
    if (product.delivery === 'true') {
      return 'delivery';
    } else {
      return 'pickup';
    }
  }

  render () {
    const { product, images, employer } = this.props;
    return (
      <div className="col-lg-8 tw-h-64">
        <div className="tw-card">
          {Object.values(product.rebates).map(r => (
            <div key={r.id} className="tw-bg-green-100 tw-mt-2 tw-mb-2 tw-border-l-4 tw-border-green-500 tw-text-green-700 tw-p-2 tw-text-sm">
              <p>{r.name} - {r.amount} {r.reduction_type == 'percent' ? '%' : '$'}</p>
            </div>
          ))}
          <ProductMedias employer={employer} product={product} images={images} />
          {employer.free_delivery_threshold && <div className="tw-flex tw-justify-center">
            <p className="tw-font-bold tw-text-center">{this.props.t('freeDelivery', { company: employer.name, minimum: employer.free_delivery_threshold })}</p>
          </div>}
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
            <div className="tw-flex tw-flex-col">
              <span className="tw-font-bold tw-text-2xl">{product.title}</span>
              <span className="tw-font-semibold">{parseFloat(product.price).toFixed(2)} $</span>
            </div>
            {product.quantity === '0' || getQuantityFromCart(product.id) >= product.quantity
              ? this.props.t('oosortoomuch') : <button onClick={(e) => { this.addToCart(e); }} className="focus:tw-bg-yellow-700 tw-bg-aa-yellow tw-px-3 tw-py-2 tw-font-bold  tw-text-sm tw-m-1 tw-self-end tw-leading-none tw-min-w-32 tw-text-black tw-cursor-pointer tw-flex tw-flex-row focus:tw-outline-none tw-items-center">
                <img src={cart} alt="Icon" className="tw-h-8 tw-mr-2" />
                {this.props.t('addToCart')}
              </button>}
          </div>
          { this.getCart() }
          <p className="tw-my-4 tw-whitespace-pre-line">{product.description}</p>
          <p className="tw-font-semibold">{this.props.t('acquisition-method')}: {this.getAcquisitionMethods()}</p>
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(ProductBox);
