export const HANDLE_CHANGE = 'HANDLE_CHANGE';
export const CLEAR_FORM = 'CLEAR_FORM';
export const ADD_ERRORS = 'ADD_ERRORS';
export const LIST_CURRENT = 'LIST_CURRENT';
export const LIST_ADD = 'LIST_ADD';
export const LIST_EDIT = 'LIST_EDIT';
export const LIST_REMOVE = 'LIST_REMOVE';
export const LIST_DESC_CURRENT = 'LIST_DESC_CURRENT';
export const LIST_DESC_ADD = 'LIST_DESC_ADD';
export const LIST_DESC_EDIT = 'LIST_DESC_EDIT';
export const LIST_DESC_REMOVE = 'LIST_DESC_REMOVE';
export const REMOVE_OVERLAY = 'REMOVE_OVERLAY';
export const SHOW_OVERLAY = 'SHOW_OVERLAY';
export const FORCE_OVERLAY = 'FORCE_OVERLAY';
export const OPEN_POPUP = 'OPEN_POPUP';
export const CLOSE_POPUP = 'CLOSE_POPUP';
export const SET_STEP = 'SET_STEP';

