import Button from 'components/common/Button';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import TextInput from './TextInput';

export class PanelChoice extends Component {

  render () {
    return (
    <div className="tw-flex tw-flex-row tw-justify-between tw-gap-4 tw-font-semibold tw-text-center">
      <div className="tw-flex tw-flex-col tw-cursor-pointer" onClick={() => {this.props.setPath('pro')}}>
        <img src="https://source.unsplash.com/MYbhN8KaaEc/300x400"></img>
        <p className="mt-1">{this.props.t('profesional')}</p>

      </div>
      <div className="tw-flex tw-flex-col tw-cursor-pointer" onClick={() => {this.props.setPath('company')}}>
        <img src="https://source.unsplash.com/gMsnXqILjp4/300x400"></img>
        <p className="mt-1">{this.props.t('company')}</p>
      </div>
    </div>);
  }
}

export default withTranslation(['signup', 'common'])(PanelChoice);
