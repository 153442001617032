import i18n from 'i18next';
import * as Cookies from 'js-cookie';
import { removeOverlay, showOverlay, forceOverlay, openPopup, closePopup, setStep } from 'redux/actions';
import store from 'redux/store';
import { notification } from 'notifications.js';
import { object } from 'prop-types';

export const getLanguage = () => {
  return i18n.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
    'en';
};

export const languageDefined = () => {
  return window.localStorage.i18nextLng !== undefined || Cookies.get('lang') !== undefined;
}

export function objt (obj) {
  if (obj) {
    if (obj.name_french && obj.name_english) {
      if (getLanguage() === 'en') {
        return obj.name_english;
      } else {
        return obj.name_french;
      }
    }
    if(obj.given_french && obj.given_english && obj.pivot.type === "GIVEN") {
      if (getLanguage() === 'en') {
        return obj.given_english;
      } else {
        return obj.given_french;
      }
    }
    if(obj.needed_french && obj.needed_english && obj.pivot.type === "NEEDED") {
      if (getLanguage() === 'en') {
        return obj.needed_english;
      } else {
        return obj.needed_french;
      }
    }

    return obj;
  }
  return undefined;
};

export function objt_need (obj, type) {
  if (obj) {
    if (getLanguage() === 'en') {
      return obj[type + '_english'];
    } else {
      return obj[type + '_french'];
    }
  }

  return undefined;
};

export function mergeCurrents(fields, oform) {
  var form = Object.assign([], oform);
  if(form?.['training_current'] && form?.['training_current'].training && form?.['training_current'].value) {
    var current = {id: 999, value: form['training_current'].value, training: form['training_current'].training};
    if(form?.['training']) {
      form['training'] = Object.assign([], form['training']);
      form['training'].unshift(current);
    } else {
      form['training'] = [current];

    }
  }
  if(form?.['work_experiences_current']) {
    var currentWE = {id: 999, value: form['work_experiences_current']};
    if(form?.['work_experiences']) {
      form['work_experiences'] = Object.assign([], form['work_experiences']);
      form['work_experiences'].unshift(currentWE);
    } else {
      form['work_experiences'] = [currentWE];
    }
  }
  return form;
}

export function formToFormData (fields, form) {
  var fd = new FormData();
  var form = mergeCurrents(fields, form);
  fields.forEach(field => {
    if (field === 'images[]') {
      if (form[field] !== undefined) {
        var images = Array.from(form[field]);
        images.forEach(image => {
          fd.append('images[]', image);
        });
      }
      return;
    }

    if (Array.isArray(form[field])) {
      if (!form[field][0]) {
        return;
      }
      if (Object.keys(form[field][0]).includes('id') && Object.keys(form[field][0]).includes('value') && !Object.keys(form[field][0]).includes('training')) {
        fd.append(field, JSON.stringify(form[field].map(f => f.value)));
        return;
      }
      fd.append(field, JSON.stringify(form[field]) || '');
      return;
    }
    if (form[field] === 0 || parseInt(form[field] === 0)) {
      fd.append(field, form[field]);
    } else {
      fd.append(field, form[field] || '');
    }
  });
  return fd;
}

export function isAuth () {
  if (Cookies.get('auth')) {
    return true;
  }
  return false;
}


export function notAffiliated() {
  if (Cookies.get('affiliated') == 'false' || Cookies.get('affiliated') === undefined) {
    return true;
  }
  return false;
}


export function removeFromCart (id) {
  if (window.localStorage.getItem('cart') !== null) {
    var cartp = JSON.parse(window.localStorage.getItem('cart'));
    if (cartp[id] !== undefined) {
      cartp[id] = cartp[id] - 1;
    }
    window.localStorage.setItem('cart', JSON.stringify(cartp));
  }
}

export function deleteFromCart (id) {
  if (window.localStorage.getItem('cart') !== null) {
    var cartp = JSON.parse(window.localStorage.getItem('cart'));
    cartp[id] = 0;
    window.localStorage.setItem('cart', JSON.stringify(cartp));
  }
}

export function addToCart (id) {
  var cartp = JSON.parse(window.localStorage.getItem('cart'));
  if (cartp === null) {
    window.localStorage.setItem('cart', JSON.stringify({ [id]: 1 }));
  } else {
    if (cartp[id] === undefined) {
      cartp[id] = 1;
    } else {
      cartp[id] = cartp[id] + 1;
    }
    window.localStorage.setItem('cart', JSON.stringify(cartp));
  }
}

export function getQuantityFromCart (id) {
  var cart = window.localStorage.getItem('cart');
  if (cart !== null) {
    var cartp = JSON.parse(cart);
    if (cartp === null || cartp[id] === null || cartp[id] <= 0) {
      return 0;
    }
    return cartp[id];
  }
}

export function emptyCart () {
  window.localStorage.setItem('cart', null);
}

export function isCartEmpty () {
  return JSON.parse(window.localStorage.getItem('cart')) === null;
}

export function getCart () {
  return JSON.parse(window.localStorage.getItem('cart'));
}

export function getAmountInCart () {
  var cart = window.localStorage.getItem('cart');
  if (cart !== null) {
    var total = 0;
    var cartp = JSON.parse(cart);
    if (cartp === null) {
      return 0;
    }
    Object.keys(cartp).forEach(k => {
      total = total + cartp[k];
    });
    return total + getAmountInCartVariant();
  }
  return 0 + getAmountInCartVariant();
}

// VARIANT

export function removeFromCartVariant (id) {
  if (window.localStorage.getItem('cartVariant') !== null) {
    var cartp = JSON.parse(window.localStorage.getItem('cartVariant'));
    if (cartp[id] !== undefined) {
      cartp[id] = cartp[id] - 1;
    }
    window.localStorage.setItem('cartVariant', JSON.stringify(cartp));
  }
}

export function deleteFromCartVariant (id) {
  if (window.localStorage.getItem('cartVariant') !== null) {
    var cartp = JSON.parse(window.localStorage.getItem('cartVariant'));
    cartp[id] = 0;
    window.localStorage.setItem('cartVariant', JSON.stringify(cartp));
  }
}

export function addToCartVariant (id) {
  var cartp = JSON.parse(window.localStorage.getItem('cartVariant'));
  if (cartp === null) {
    window.localStorage.setItem('cartVariant', JSON.stringify({ [id]: 1 }));
  } else {
    if (cartp[id] === undefined) {
      cartp[id] = 1;
    } else {
      cartp[id] = cartp[id] + 1;
    }
    window.localStorage.setItem('cartVariant', JSON.stringify(cartp));
  }
}

export function getQuantityFromCartVariant (id) {
  var cart = window.localStorage.getItem('cartVariant');
  if (cart !== null) {
    var cartp = JSON.parse(cart);
    if (cartp === null || cartp[id] === null || cartp[id] <= 0) {
      return 0;
    }
    return cartp[id];
  }
}

export function emptyCartVariant () {
  window.localStorage.setItem('cartVariant', null);
}

export function isCartVariantEmpty () {
  return JSON.parse(window.localStorage.getItem('cartVariant')) === null;
}

export function getCartVariant () {
  return JSON.parse(window.localStorage.getItem('cartVariant'));
}

export function getAmountInCartVariant () {
  var cart = window.localStorage.getItem('cartVariant');
  if (cart !== null) {
    var total = 0;
    var cartp = JSON.parse(cart);
    if (cartp === null) {
      return 0;
    }
    Object.keys(cartp).forEach(k => {
      total = total + cartp[k];
    });
    return total;
  }
  return 0;
}

export function changeMode (id, mode) {
  var modes = JSON.parse(window.localStorage.getItem('modes'));
  if (modes !== null) {
    modes[id] = mode;
  } else {
    modes = {};
    modes[id] = mode;
  }
  window.localStorage.setItem('modes', JSON.stringify(modes));
}

export function getMode (id) {
  var modes = JSON.parse(window.localStorage.getItem('modes'));
  if (modes !== null) {
    if (modes[id]) {
      return modes[id];
    }
  }
  return null;
}

export function getModes () {
  return JSON.parse(window.localStorage.getItem('modes'));
}

export function changeModeVariant (id, mode) {
  var modes = JSON.parse(window.localStorage.getItem('modesVariant'));
  if (modes !== null) {
    modes[id] = mode;
  } else {
    modes = {};
    modes[id] = mode;
  }
  window.localStorage.setItem('modesVariant', JSON.stringify(modes));
}

export function getModeVariant (id) {
  var modes = JSON.parse(window.localStorage.getItem('modesVariant'));
  if (modes !== null) {
    if (modes[id]) {
      return modes[id];
    }
  }
  return null;
}

export function getModesVariant () {
  return JSON.parse(window.localStorage.getItem('modesVariant'));
}

export function authOverlay () {
  if (isAuth()) {
    return true;
  } else {
    store.dispatch(showOverlay());
    return false;
  }
}

export function closeOverlay () {
  store.dispatch(removeOverlay());
}

export function forceOpenOverlay () {
  if (isAuth()) {
    return true;
  } else {
    store.dispatch(forceOverlay());
    return false;
  }
}

export function utilsOpenPopup (name) {
  store.dispatch(openPopup(name));
}

export function utilsSetStep (step) {
  store.dispatch(setStep(step));
}


export function utilsClosePopup () {
  store.dispatch(closePopup());
}

export function encodeDataToURL (data) {
  return Object
    .keys(data)
    .map(value => `${value}=${encodeURIComponent(data[value])}`)
    .join('&');
}

export function dangerNotification(message) {
  notification('Oops!', message, 'danger');
}

export function successNotification(message) {
  notification(i18n.t('common:success'), message, 'success');
}

export function capitalize(s) {
  return s && s[0].toUpperCase() + s.slice(1);
}

export function findIndex(input, name) {
  return input.findIndex(e => 
    Array.isArray(e) // check if element is array
      ? e.some(x => x.name === name) // if yes, check if contains name
      : e.name === name // if no, check if elements name is equal name 
  )
}

export function getObjectId(object, id) {
  if(object.filter(obj => obj.id == id).length !== 0) {
    return object.filter(obj => obj.id == id)[0];
  }
  return undefined;
}
