import Button from 'components/common/Button';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { handleChange } from 'redux/actions';
import ReactLoading from 'react-loading';
import Webcam from 'react-webcam';
import Countdown from "react-countdown";
import FileInput from './FileInput';
import reactSelectCjsProd from 'react-select/dist/react-select.cjs.prod';

const initialState = {capturing: false, data: [], file: false, current: 'webcam', url: undefined, capturingStart: 0, userMedia: false, devicesCount: 0};
export class RecordingSummaryPanel extends Component {

  constructor() {
    super();
    this.state = initialState;
    this.videos = ['first_question', 'second_question', 'third_question', 'fourth_question', 'fifth_question'];
    this.state = {currentVideo: 0, urls: [], loading: true};
    this.getUrls = this.getUrls.bind(this);
    this.loadNextVideo = this.loadNextVideo.bind(this);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.getUrls();
  }

  getUrls() {
    var urls = [];
    this.videos.forEach(index => {
      urls.push(URL.createObjectURL(this.props.form[index]));
    });
    this.setState({urls: urls, loading: false});
  }

  loadNextVideo() {
    this.setState({currentVideo: this.state.currentVideo + 1})
  }

  onLoad() {
    this.myRef.current.play();
  }
  

  // eslint-disable-next-line react/require-render-return
  render () {
    if(this.state.loading) {
      return (
        <div className="tw-w-full tw-h-full tw-flex tw-flex-row tw-justify-center tw-items-center" style={{ backgroundColor: '#434349' }}>
          <ReactLoading type="bubbles" color="#f5ff00" />
        </div>
      );
    }
    return (
      <div>
        <video ref={this.myRef} controls autoplay onEnded={this.loadNextVideo} onLoad={() => {this.onLoad()}} src={this.state.urls[this.state.currentVideo]}></video>
      </div>
    );
  }
}

const mapStateToProps = ({ input }) => {
  return {
    form: input.signup
  };
};


export default withRouter(connect(mapStateToProps, {handleChange})(withTranslation(['signup', 'common', 'input'])(RecordingSummaryPanel)));
