import { connect } from 'react-redux';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Button from './Button';
import * as Cookies from 'js-cookie';

export class EmployerPrompt extends Component {
  render () {
    if(this.props.display == true) {
      return (
        <div class="tw-fixed tw-z-10 tw-inset-0 tw-overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="tw-flex tw-items-end tw-justify-center tw-min-h-screen tw-pt-4 tw-px-4 tw-pb-20 tw-text-center sm:tw-block sm:tw-p-0">
          <div class="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" aria-hidden="true"></div>

          <span class="tw-hidden sm:tw-inline-block sm:tw-align-middle sm:tw-h-screen" aria-hidden="true">&#8203;</span>
          <div class="tw-inline-block tw-align-bottom tw-bg-white tw-rounded-lg tw-px-4 tw-pt-5 tw-pb-4 tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all sm:tw-my-8 sm:tw-align-middle sm:tw-max-w-lg sm:tw-w-full sm:tw-p-6">
            <div>
              <div class="mt-3 text-center sm:mt-5">
                <h3 class="tw-text-center tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900" id="modal-title">
                {this.props.t('choice')}
                </h3>
              </div>
            </div>
            <div class="tw-mt-5 sm:tw-mt-6 sm:tw-grid sm:tw-grid-cols-2 sm:tw-gap-3 sm:tw-grid-flow-row-dense">
              <Button text={this.props.t('coffeeMachine')} action={() => {
                  var switchee = Cookies.get('switchee');
                  var current = Cookies.get('current');                
                  Cookies.set('switchee', current);
                  Cookies.set('current', switchee);
                  window.location.reload();    
              }} />
              <Button text={this.props.t('business')} action={() => {
                window.location.href = 'https://business.alpeconnexion.com/employers/login?login_token=' + Cookies.get('login_token');
              }} />
            </div>
          </div>
        </div>
      </div>

      );
    }
    return null;
  }
}


export default withTranslation('employers')(withRouter(EmployerPrompt));
