import Button from 'components/common/Button';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { handleChange } from 'redux/actions';
import ReactLoading from 'react-loading';
import Webcam from 'react-webcam';
import Countdown from "react-countdown";
import FileInput from './FileInput';
import { BrowserView, MobileView } from 'react-device-detect';

const initialState = {capturing: false, data: [], file: false, current: 'webcam', url: undefined, capturingStart: 0, userMedia: false, devicesCount: 0};
export class RecordingPanel extends Component {

  constructor() {
    super();
    this.state = initialState;
    this.webcamRef = React.createRef();
    this.mediaRecorderRef = React.createRef();
    this.countdown = React.createRef();
    this.startCapture = this.startCapture.bind(this);
    this.stopCapture = this.stopCapture.bind(this);
    this.handleDataAvailable = this.handleDataAvailable.bind(this);
  }

  componentDidMount() {
    if(typeof navigator.mediaDevices.enumerateDevices == 'function') {
      navigator.mediaDevices.enumerateDevices().then(
        (devices) => {
          console.log(devices.filter(({kind}) => kind === 'videoinput').length);
          this.setState({
            devicesCount: devices.filter(({kind}) => kind === 'videoinput').length
          });
        }
      )
    }
  }

  componentDidUpdate() {
    if(this?.webcamRef?.current?.stream && this.webcamRef.current.stream instanceof MediaStream) {
      if(this.mediaRecorderRef.current == null) {
        this.mediaRecorderRef.current = new MediaRecorder(this.webcamRef.current.stream, {
          mimeType: "video/webm"
        });
        this.mediaRecorderRef.current.addEventListener(
          "dataavailable",
          this.handleDataAvailable
        );
        if(this.mediaRecorderRef.current == null) {
          this.setState({file: true});
        }  
      }
  
    }
  }

  startCapture() {
    this.setState({capturing: true, capturingStart: Date.now() + 30000});
    this.props.handleChange(this.props.name, null, 'signup')
    if(this.countdown?.current && (this.countdown.current.isPaused() || this.countdown.current.isCompleted())) {
      this.countdown.current.start();
    }
    this.mediaRecorderRef.current.start();
  }

  handleDataAvailable(data) {
    if (data.data.size > 0) {
      this.setState((state, props) => {
        return {
          data: state.data.concat(data.data)
        }
      },       
      () => {this.props.handleChange(this.props.name, new Blob(this.state.data, {type: "video/webm"}), 'signup')}
      )
    }
  }

  stopCapture() {
    if(this?.mediaRecorderRef?.current?.state != 'inactive') {
      this.mediaRecorderRef.current.stop();
    }
    if(this?.countdown?.current) {
      this.countdown.current.pause();
    }
    this.setState({capturing: false});
  }

  renderer = ({ hours, minutes, seconds, completed, api }) => {
    if (completed || api.isPaused()) {
      return <span className="tw-block tw-w-full tw-text-center">{this.props.t('countdownDone')}</span>;
    } else {
      return (
        <span className="tw-block tw-w-full tw-text-center">
          {seconds} {this.props.t('secondsRemaining')}
        </span>
      );
    }
  };

  // eslint-disable-next-line react/require-render-return
  render () {
    var {t, name} = this.props;
    if(!name) {
      return null;
    }
    return (
      <div>
        <BrowserView>
      {this.state.file == false && this.state.devicesCount > 0 &&
        <>
          <div className={this.state.current == 'playback' && 'tw-hidden'}>
            <Webcam mirrored={true} ref={this.webcamRef} onUserMedia={() => {this.setState({userMedia: true})}} />

            {this.state.capturingStart != 0 && <Countdown ref={this.countdown} renderer={this.renderer} key={this.state.capturingStart} date={this.state.capturingStart} onComplete={this.stopCapture} />}
            {this.state.userMedia === true && !this.props?.form?.[name] && (this.state.capturing ? (
              <Button key="stop" text={t('stopCapture')} action={this.stopCapture} />
                  ) : (
              <Button key="start" text={t('startCapture')} action={this.startCapture} />
            ))}
            {this.props?.form?.[name] && this.props.form[name].size > 0 && 
            <div>
                <Button key="playback" text={t('playback')} action={() => {this.setState({current: 'playback', url: URL.createObjectURL(this.props.form[name])})}} />
                <Button key="restart" text={t('restart')} action={() => {this.props.handleChange(name, undefined, 'signup'); this.setState({data: []})}} />

              </div>}
          </div>
          
          {this.state.current == 'playback' &&
          <div>
            <video controls>
              <source src={this.state.url} />
              </video> 
              <Button key="record" text={t('record')} action={() => {this.setState({current: 'webcam', url: undefined})}} />
          </div>}
        </>
      }
      {(this.state.file == true || this.state.userMedia == false) &&
        <>
          <FileInput key={name} name={name} subtitle={`Formats: avi, mp4, mov, qt`} accepted="avi,mp4,mov,qt" required={false} noLabel={true} noTitle={true} video={true} />
        </>
      }
      </BrowserView>
      <MobileView>
        <FileInput key={name} name={name} subtitle={`Formats: avi, mp4, mov, qt`} accepted="avi,mp4,mov,qt" required={false} noLabel={true} noTitle={true} video={true} />
      </MobileView>
      </div>
    );
  }
}

const mapStateToProps = ({ input }) => {
  return {
    form: input.signup
  };
};


export default withRouter(connect(mapStateToProps, {handleChange})(withTranslation(['signup', 'common', 'input'])(RecordingPanel)));
