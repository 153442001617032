import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Screen from 'components/templates/Screen.js';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { emptyCart, emptyCartVariant } from 'utils';
import instance from 'axiosInstance';

export class OrderSuccess extends Component {
  constructor (props) {
    super(props);
    this.state = { loading: true, orders: [], nothingFound: false };
  }

  getData () {
    instance.get('/products/orders/' + this.props.location.search.split('=')[1]).then((s) => {
      this.setState({ loading: false, orders: s.data.orders });
    }, e => {
      this.setState({ loading: false, nothingFound: true });
    });
  }

  componentDidMount () {
    emptyCart();
    emptyCartVariant();
    this.getData();
  }

  render () {
    return (
      <Screen showCart={true} type="triangle" loading={this.state.loading} title={this.props.t('pages:orderSuccess')}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 tw-h-64 mx-auto">
              <div className="tw-card">
                {this.state.nothingFound === true
                  ? <p>{this.props.t('noOrder')}</p>
                  : (this.state.orders.length > 0 && this.state.orders.map(order => (
                    <div key={order.id}>
                      <h3 className="tw-text-lg tw-font-bold">{this.props.t('orderNumber', { number: order.id })}</h3>
                      <ul>
                        {order.products.map(product => (
                          <li key={product.id}><span className="tw-fotn-semibold">{product.title}</span> ({product.quantity}x)</li>
                        ))}
                      </ul>
                    </div>
                  )))}
              </div>
            </div>
          </div>
        </div>
      </Screen>
    );
  }
}

OrderSuccess.propTypes = {
  t: PropTypes.func.isRequired
};

export default withRouter(withTranslation('common')(OrderSuccess));
