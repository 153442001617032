import React, { Component } from 'react';
import Screen from 'components/templates/Screen';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import * as Cookies from 'js-cookie';
import TextInput from 'components/forms/TextInput';
import Form from 'components/forms/Form';
import Submit from 'components/forms/Submit';
import { connect } from 'react-redux';
import { addErrors } from 'redux/actions';
import { formToFormData, successNotification, utilsOpenPopup } from 'utils';
import instance from 'axiosInstance';
import YesNoInput from 'components/forms/YesNoInput';

export class PasswordSetup extends Component {

  constructor () {
    super();
    this.state = {loading: false};
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  pushHistory () {
    if (Cookies.get('origin') !== undefined) {
      var origin = Cookies.get('origin');
      Cookies.remove('origin');
      this.props.history.push(origin);
    } else {
      this.props.history.push('/');
    }
  }

  handleSubmit (e) {
    e.preventDefault();
    this.setState({ loading: true });
    var fd = formToFormData(['password', 'password_confirmation', 'terms'], this.props.form);
    fd.append('hash', this.props.location.search);
    instance.post('/password-setup', fd).then((success) => {
      successNotification(this.props.t('passwordSetupDone'))
      Cookies.set('welcomeSeen', true);
      Cookies.set('auth', success.data.token);
      Cookies.set('email_verified', success.data.email_verified);
      if(success.data.employer !== undefined) {
        Cookies.set('employer', success.data.employer);
        Cookies.set('current', success.data.current);
        Cookies.set('switchee', success.data.switchee);
      }
      if(Cookies.get('origin')) {
        if(Cookies.get('origin') == '/affiliation') {
          utilsOpenPopup('affiliate');
          this.props.history.push('/');
          Cookies.set('onboardingSeen', true)
        } else {
          this.props.history.push(Cookies.get('origin'));
        }
      } else {
        if(success.data.type == 'EMPLOYEE' || success.data.type == 'SELF-EMPLOYED') {
          this.props.history.push({pathname: '/', state: {popup: 'onboarding'}});
        } else {
          this.props.history.push('/');
        }  
      }
  }, (error) => {
      this.props.addErrors(error.response.data.errors, 'password_setup');
    }).then(() => {
      this.setState({ loading: false });
    });
  }


  render () {
    Cookies.set('email_verified', true);
    return (
      <Screen type="video" containerClasses="tw-h-full tw-flex tw-flex-row tw-justify-center tw-items-center" title={this.props.t('pages:passwordSetup')} loading={this.state.loading}>
        <div className="password-setup-modal tw-flex tw-flex-col tw-justify-center tw-space-y-4 tw-items-center tw-text-center tw-card-transparent tw-p-4">
          <p>{this.props.t('setupPassword')}</p>
          <Form name="password_setup" classes="" classesOverwrite={true} onSubmit={this.handleSubmit}>
            <TextInput name="password" type="password" />
            <TextInput name="password_confirmation" type="password" />
            <p className="tw-cursor-pointer tw-underline hover:tw-no-underline tw-text-aa-yellow" onClick={() => {window.location.href = 'https://api.alpeconception.com/privacy-policy'}}>{this.props.t('pages:terms')}</p>
            <YesNoInput noLabel={true} disableNo={true} name="terms" whiteText={true}/>
            <Submit />
          </Form>
        </div>
      </Screen>
    );
  }
}

PasswordSetup.defaultProps = {
  form: {}
};

const mapStateToProps = ({ input }) => {
  return {
    form: input.password_setup
  };
};

export default withRouter(connect(mapStateToProps, { addErrors })(withTranslation(['common', 'pages'])(PasswordSetup)));
