import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete';
import { withTranslation } from 'react-i18next';
import { objt } from 'utils';
import SearchBarInput from './SearchBarInput';

export class SearchBar extends Component {
  constructor (props) {
    super(props);
    this.state = { search: this.props.search };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange (value) {
    this.setState({ search: value });
  }

  handleSubmit (e, value = null) {
    e.preventDefault();
    if(value) {
      this.props.newSearch(value);
    } else {
      this.props.newSearch(this.state.search);
    }
  }

  getItems() {
    var items = this.props.items;

    var intermediate = items.map(item => {
      if(item.search) {
        return item.search;
      } else if(item.name_french && item.name_english) {
        return objt(item);
      }
      return null;
    }).filter(item => item != null);
    var i2 = intermediate.map(x => typeof x === 'string' ? x.toLowerCase() : x);
    var final = intermediate.filter(function(elem, pos) {
      return i2.indexOf(elem.toLowerCase()) == pos;
    });
    return final;
  }

  render () {
    return (
      <div class="tw-w-full" style={{ height: '75%'}}>
      <div className="tw-flex tw-flex-row tw-text-sm tw-w-10/12 md:tw-w-8/12 lg:tw-w-9/12 tw-h-full tw-mx-auto md:tw-mr-auto md:tw-ml-0 lg:tw-mx-auto" style={{ background: '#636363' }}>
        <SearchBarInput suggestions={this.getItems()} handleSubmit={this.handleSubmit} value={this.state.search} setValue={this.handleChange} />
        {/* <Autocomplete
        getItemValue={(item) => item.label}
        items={this.getItems()}
        renderItem={(item, isHighlighted) =>
          <div style={{ background: isHighlighted ? 'rgb(127,127,127)' : '#636363', color: 'white', paddingLeft: 10 }}>
            {item.label}
          </div>
        }
        renderInput={(props) => (
          <input placeholder={this.props.t('findJobOffer')} {...props} onChange={this.handleChange} type="text" className="tw-p-3 tw-text-xl tw-flex-1 tw-h-full" style={{ height: '100%', background: '#636363' }}/>
        )}
        wrapperStyle={{height: '100%'}}
        value={this.state.search}
        onChange={(e) => this.setState({search: e.target.value})}
        onSelect={(val) => this.setState({search: val})}
        menuStyle={{
          borderRadius: '0',
          boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
          background: '#636363',
          padding: '2px 0',
          fontSize: '90%',
          position: 'fixed',
          overflow: 'auto',
          maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
        }}
        onKeyDown={
          (e) => {
            e.keyCode === 13 && this.handleSubmit(e);
          }
        }
      /> */}

        {/* <input onKeyDown={
          (e) => {
            e.keyCode === 13 && this.handleSubmit(e);
          }
        } value={this.state.search} placeholder={this.props.t('findJobOffer')} onChange={this.handleChange} type="text" className="tw-p-3 tw-text-xl tw-flex-1 tw-h-full" style={{ height: '100%', background: '#636363' }}/> */}
        {/* <ReactSearchAutocomplete
            items={this.props.item}
            styling={{
              height: '100%',
              backgroundColor: '#636363',
              width: '100px',
              borderRadius: 0,
              borderColor: 'transparent'
            }}

            autoFocus
          />
 */}
        <img onClick={this.handleSubmit} src="https://s.svgbox.net/hero-solid.svg?ic=search&fill=white" width="30" className="tw-cursor-pointer tw-mr-1 tw-ml-1" />
      </div>
      </div>
    );
  }
}

export default withTranslation(['common'])(SearchBar);
