import React, { Component } from 'react';
import instance from 'axiosInstance';
import ReactLoading from 'react-loading';
import profile from 'img/placeholder/profile.svg';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import back from 'img/icons/back.svg';

export class Discussion extends Component {
  constructor () {
    super();
    this.state = { loading: true, sending: false, messages: [], other: {}, message: '' };
    this.getMessages = this.getMessages.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
  }

  getMessages () {
    if (this.state.sending === false) {
      instance.get('/messages/' + this.props.id).then(s => {
        this.setState({ loading: false, messages: s.data.messages, other: s.data.other });
      });
    }
  }

  componentDidMount () {
    this.getMessages();
    setInterval(() => this.getMessages(), 15000);
  }

  getProfilePic (pp) {
    if (!pp) {
      return profile;
    }
    return (process.env.REACT_APP_STORAGE_URL + pp).replace(/([^:]\/)\/+/g, '$1');
  }

  sendMessage () {
    if (this.state.message !== '') {
      var email = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i;
      var phone = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
      if(email.test(this.state.message) || phone.test(this.state.message)) {
        alert(this.props.t('noPhoneEmail'))
      } else {
        var fd = new FormData();
        fd.append('message', this.state.message);
        this.setState(state => {
          const messages = state.messages.concat({
            sender_id: -1,
            message: state.message
          });
          return {
            messages,
            message: '',
            sending: true
          };
        });
        instance.post('/messages/' + this.props.id, fd).then(s => {
          this.setState({ sending: false, messages: s.data.messages });
        });
      }
    }
  }

  render () {
    if (this.state.loading) {
      return (
        <div className="tw-w-full tw-h-full tw-flex tw-flex-row tw-justify-center tw-items-center" style={{ backgroundColor: '#434349' }}>
          <ReactLoading type="bubbles" color="#f5ff00" />
        </div>

      );
    }

    return (
      <div className="tw-w-full tw-h-full tw-flex tw-flex-col">
        <div style={{ height: 80, backgroundColor: 'rgba(67, 67, 73, 0.8)', color: 'white' }} className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-px-4">
          <div className="tw-flex tw-flex-row tw-items-center">
            <img src={back} height="30" width="30" className="tw-mr-4 tw-cursor-pointer" onClick={this.props.quitConvo} />
            <div style={{ height: 60, width: 60 }}className="">
              <img className="tw-h-full tw-w-full " src={this.getProfilePic(this.state.other.pp)} />
            </div>
            <div className="tw-flex tw-flex-col tw-justify-center tw-ml-2">
              <span className="tw-underline hover:tw-no-underline tw-cursor-pointer" onClick={() => {
                if (this.state.other.type === 'EMPLOYER') {
                  this.props.history.push('/shop/' + this.state.other.related_id);
                } else {
                  this.props.history.push('/profile/' + this.state.other.related_id);
                }
              }}>{this.state.other.name}</span>
              {this.state.other.type === 'EMPLOYER' ? <span className="tw-text-xs">{this.props.t('employer')}</span> : null}
            </div>
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-space-y-2 tw-justify-end p-3 tw-flex-1" style={{ height: 'calc(100% - 140px)', overflowY: 'auto' }}>
          {this.state.messages.map(message => {
            if (message.sender_id == this.state.other.user_id) {
              return <p style={{ maxWidth: '70%' }} className="tw-break-all p-2 px-3 -2xl mr-auto tw-bg-aa-yellow">{message.message}</p>;
            } else {
              return <p style={{ maxWidth: '70%' }} className="tw-break-all p-2 px-3 -2xl ml-auto tw-bg-gray-300">{message.message}</p>;
            }
          })}
        </div>
        <div className="tw-flex tw-flex-row tw-items-center p-2" style={{ minHeight: 60, backgroundColor: 'rgba(67, 67, 73, 0.8)' }}>
          <input className="tw-px-1 tw-h-full tw-flex-1  tw-text-black tw-bg-gray-400" type="text" value={this.state.message} onChange={(e) => { this.setState({ message: e.target.value }); }}
            onKeyDown={
              (e) => {
                e.keyCode === 13 && this.sendMessage();
              }
            }></input>
          <img className="tw-ml-1 tw-cursor-pointer" onClick={this.sendMessage} src="https://s.svgbox.net/materialui.svg?fill=white&ic=send" height="40" width="40" type="submit" />
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(withRouter(Discussion));
