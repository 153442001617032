import React, { Component } from 'react';
import Screen from 'components/templates/Screen';
import { withTranslation } from 'react-i18next';
import instance from 'axiosInstance';
import ShopProduct from './../../components/shop/ShopProduct';
import Pagination from './../../components/shop/Pagination';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import CompanyBox from './../../components/jobOffers/CompanyBox';
import CompanyHeaderShop from 'components/shop/CompanyHeaderShop';
import CompanyFooter from 'components/shop/CompanyFooter';

export class EmployerShop extends Component {
  constructor () {
    super();
    this.state = { loadingProducts: true, componentsLoading: false, products: [], employer: {}, count: 1 };
    this.getProducts = this.getProducts.bind(this);
  }

  componentDidMount () {
    instance.get('/products/count/' + this.props.match.params.id).then((s) => {
      this.setState({ count: s.data.count });
    });
    this.getProducts();
  }

  getProducts () {
    this.setState({ loadingProducts: true, loadingBestSellers: true });
    const params = queryString.parse(this.props.location.search);
    if (!params.page || parseInt(params.page) === 1) {
      if (!params.page) {
        var page = 1;
      } else {
        page = parseInt(params.page);
      }
      instance.get('/products/employers/' + this.props.match.params.id + '/page/' + page).then((s) => {
        this.setState({ loadingProducts: false, employer: s.data.employer, products: s.data.products });
      });
    }
  }

  render () {
    const { products, employer } = this.state;
    const params = queryString.parse(this.props.location.search);
    return (
      <Screen showCart={true} type="triangle" loading={(this.state.loadingProducts !== false && this.state.loadingBestSellers !== false)} title={this.props.t('pages:shop')}>

        <div className="container">
          <div className="row">
            <CompanyHeaderShop employer={employer} />
            <div className="col-lg-12">
              <Pagination update={this.getProducts} count={this.state.count} page={params.page || 1} />
              {products.length === 0
                ? <div className="tw-card">
                  <p>{this.props.t('noProductPage')}</p>
                </div>

                : <>
                  <div>
                    <h2 className="tw-text-xl tw-font-bold tw-mb-2 tw-text-white">{this.props.t('newProducts')}</h2>
                    <div className="row">
                      {products.map(product => <ShopProduct employerPage={true} key={product.id} product={product} />)}
                    </div>
                  </div>
                </>}
              <Pagination update={this.getProducts} count={this.state.count} page={params.page || 1} />

            </div>
            <CompanyFooter employer={employer} />
          </div>
        </div>
      </Screen>
    );
  }
}

export default withRouter(withTranslation('common')(EmployerShop));
