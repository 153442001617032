import { ADD_ERRORS } from '../actionTypes';

const input = (state = {}, action) => {
  switch (action.type) {
    case ADD_ERRORS: {
      const { errors, form } = action.payload;
      return {
        ...state,
        [form]: {
          ...errors
        }
      };
    }
    default:
      return state;
  }
};

export default input;
