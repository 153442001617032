import { isAuth } from 'utils';
import { SHOW_OVERLAY, REMOVE_OVERLAY, FORCE_OVERLAY } from '../actionTypes';

const overlay = (state = { overlay: !(isAuth()) }, action) => {
  switch (action.type) {
    case SHOW_OVERLAY: {
      return {
        ...state,
        overlay: true
      };
    }
    case REMOVE_OVERLAY: {
      return {
        ...state,
        overlay: false,
        forceOpen: false
      };
    }
    case FORCE_OVERLAY: {
      return {
        ...state,
        forceOpen: true
      };
    }

    default:
      return state;
  }
};

export default overlay;
