import CompanyBox from 'components/jobOffers/CompanyBox';
import ProductBox from 'components/shop/ProductBox';
import VariantProductBox from 'components/shop/VariantProductBox';
import React, { Component } from 'react';
import Screen from 'components/templates/Screen';
import { withTranslation } from 'react-i18next';
import instance from 'axiosInstance';
import { getAmountInCart } from 'utils';
import { Helmet } from 'react-helmet';

export class ProductPage extends Component {
  constructor () {
    super();
    this.state = { loading: true, componentsLoading: false, cartSize: getAmountInCart() };
  }

  componentDidMount () {
    instance.get('/products/' + this.props.match.params.id).then((s) => {
      this.setState({ loading: false, product: s.data.product, employer: s.data.employer, images: s.data.images });
    }, (e) => {
      this.setState({ loading: false, error: true });
    });
  }

  render () {
    const { product, employer, images } = this.state;
    if (this.state.error === true) {
      return (
        <Screen overall={true} showCart={true} type="shop" loading={this.state.loading} title={this.props.t('pages:shop')}>

          <div className="container">
            <div className="row">
              <div className="col-lg-8 tw-h-64 mx-auto">
                <div className="tw-card">
                  {this.props.t('noProductFound')}
                </div>
              </div>
            </div>
          </div>
        </Screen>
      );
    }
    return (
      <Screen overall={true} showCart={true} type="triangle" loading={this.state.loading} cartSize={this.state.cartSize} title={this.props.t('pages:shop')}>
        <div className="container">
          <div className="row">
            {product !== undefined &&                 
            <Helmet>
              <title>AVA - {product.title}</title>
            </Helmet>
            }            
            <CompanyBox employer={employer}/>
            {(product !== undefined && product.subproducts == 1) ? <VariantProductBox employer={employer} product={product} cartUpdate={(n) => this.setState({ cartSize: n })}images={images}/> : <ProductBox employer={employer} product={product} cartUpdate={(n) => this.setState({ cartSize: n })}images={images}/>}

          </div>
        </div>
      </Screen>
    );
  }
}

export default withTranslation('common')(ProductPage);
