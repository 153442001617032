import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class FormGroup extends Component {
  getSize () {
    var length = this.props.children.length;
    if (length >= 2 && length <= 5) {
      return `tw-w-1/${length}`;
    } else if (length === 1) {
      return 'tw-w-full';
    }
  }

  render () {
    return (
      <div className="tw-flex tw-flex-row">
        {this.props.children.map((child) => {
          return React.cloneElement(child, { size: this.getSize() });
        })}
      </div>
    );
  }
}

FormGroup.propTypes = {
  children: PropTypes.array.isRequired
};

export default FormGroup;
