import React, { Component } from 'react';
import FormContext from 'context/FormContext';
import TextInputWithContext from './TextInputWithContext';

export class TextInput extends Component {
  render () {
    return (
      <FormContext.Consumer>
        {context =>
          <TextInputWithContext {...this.props} context={context} />
        }
      </FormContext.Consumer>

    );
  }
}
export default TextInput;
