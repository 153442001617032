import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from 'locales/en';
import fr from 'locales/fr';

i18n
  .use(initReactI18next)
  .init({
    lng: (typeof window !== 'undefined' && window.localStorage.i18nextLng) || 'fr',
    fallbackLng: 'fr',
    resources: {
      en: en,
      fr: fr
    }
  });

export default i18n;
