import { isAuth } from 'utils';
import { OPEN_POPUP, CLOSE_POPUP, SET_STEP } from '../actionTypes';

const popup = (state = {popup: undefined, step: 4}, action) => {
  switch (action.type) {
    case OPEN_POPUP: {
      return {
        ...state,
        popup: action.payload.name 
      };
    }
    case CLOSE_POPUP: {
      return {
        ...state,
        popup: undefined 
      };
    }
    case SET_STEP: {
      return {
        ...state,
        step: action.payload.step 
      };
    }

    default:
      return state;
  }
};

export default popup;
