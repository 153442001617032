var fr = {
  input: {
    required: '(requis)',
    upload: 'Télécharger',
    noFileSelected: 'Aucun fichier sélectionné',
    removeFile: 'Retirer le fichier'
  },
  pages: {
    askDemo: 'Demander une démonstration',
    sendApplication: 'Créer mon profil',
    coffeeMachine: 'Machine à Café',
    newsFeed: 'Fil d\'actualité',
    messages: 'Messagerie',
    login: 'Connexion',
    myProfile: 'Mon profil',
    modifyProfile: 'Modifier mon profil',
    jobs: 'Emplois',
    verifyEmail: 'Vérification d\'email',
    verifyNewEmail: 'Vérification du nouveau email',
    emailVerified: 'Email vérifié!',
    settings: 'Paramètres',
    terms: 'Conditions d\'utilisation',
    newEmailVerified: 'Verification du nouveau email',
    comingSoon: 'En construction',
    logout: 'Déconnexion',
    virtualOffice: 'Bureau virtuel',
    companies: 'Entreprises',
    shop: 'Boutique',
    cart: 'Panier',
    orderSuccess: 'Succès de la commande!',
    policies: 'Politiques',
    profileOf: 'Profil de',
    createPost: 'Créer une publication',
    post: 'Publication',
    home: 'Accueil',
    profile: 'Profil',
    modifyPost: 'Modifier une publication',
    dashboard: 'Tableau de bord',
    managePosts: 'Gérer mes publications',
    purchases: 'Achats',
    trainings: 'Formations/communauté',
    signup: 'Inscription',
    passwordSetup: 'Choix du mot de passe',
    affiliation: 'Affiliation' 
  },
  common: {
    or: "ou",
    first_name: 'Prénom',
    last_name: 'Nom de famille',
    email: 'Adresse courriel',
    email_confirmation: 'Confirmation d\'adresse courriel',
    phone_number: 'Numéro de téléphone',
    postal: 'Code postal',
    city: 'Ville',
    field_of_work: 'Domaine de travail',
    training: 'Formations',
    work_experiences: 'Expériences de travail (poste, entreprise, date)',
    salary_id: 'Salaire demandé',
    availability: 'Disponibilités',
    job_type: 'Type d\'emploi',
    interests: 'Intérêts',
    cvF: 'Curriculum Vitae (CV)',
    uploadCVFormats: 'Formats acceptés: image, pdf, docx',
    cv: 'Envoyer mon CV',
    videoCV: 'CV Vidéo',
    uploadVideoCVFormat: 'Formats acceptés: tout format vidéo',
    video_cv: 'Envoyer mon CV vidéo',
    submit: 'Envoyer',
    businessName: 'Nom de l\'entreprise',
    contactFirstName: 'Prénom du contact',
    contactLastName: 'Nom de famille du contact',
    businessAddress: 'Adresse de l\'entreprise',
    officePhoneNumber: 'Numéro de téléphone au bureau',
    cellPhoneNumber: 'Numéro de téléphone cellulaire',
    login: 'Se connecter',
    password: 'Mot de passe',
    password_confirmation: 'Confirmation du mot de passe',
    noAccount: 'Pas de compte? S\'inscrire',
    modify: 'Modifier les informations',
    myInfos: 'Mes informations',
    myDocs: 'Mes documents',
    downloadCV: 'Télécharger mon CV',
    downloadVideoCV: 'Télécharger mon CV vidéo',
    like: 'J\'aime',
    comment: 'Commenter',
    share: 'Partager',
    verifyEmail: 'Nous vous avons envoyé un email de vérification. Veuillez cliquer sur le bouton afin de vérifier votre email. (vérifiez le dossier "Courrier Indésirable"/"Spam"!)',
    verifyNewEmail: 'Nous vous avons envoyé un email à l\'adresse {{email}} afin de vérifier que c\'est bien votre nouvelle adresse email. Veuillez cliquer sur le bouton afin de vérifier votre email. (vérifiez le dossier "Courrier Indésirable"/"Spam"!)',
    verifyNewEmailOthers: 'Vos autres modifications ont bien été sauvegardées sur votre profil.',
    resendEmail: 'Renvoyer le email',
    emailResent: 'Email renvoyé!',
    emailVerified: 'Votre email a bien été vérifié. Vous pouvez désormais utiliser le site.',
    backToHome: 'Retourner à la page initiale',
    noVideoCV: 'Vous n\'avez pas encore envoyé de CV vidéo',
    generalInformations: 'Informations générales',
    professionalInformations: 'Informations professionnelles',
    documents: 'Documents',
    undefined: 'Non-défini',
    confirmPasswordToGoForward: 'Entrez votre mot de passe pour confirmer les modifications',
    language: 'Langage',
    fr: 'Français',
    en: 'Anglais',
    readTerms: 'Lire les conditions d\'utilisations',
    goBack: 'Retour',
    fixErrors: 'Il y a eu une erreur lors de l\'envoi. Veuillez revérifier les champs.',
    newEmailVerified: 'Votre nouveau email a bien été validé. Désormais, veuillez utiliser cet email pour vous connecter.',
    'email-used-new-email': 'Le nouveau email a déjà été pris par un autre compte que le vôtre.',
    'invalid-new-email-code': 'Il y a eu un problème avec la vérification de votre email. Veuillez réessayer.',
    comingSoonText: 'Cette page/fonctionnalité n\'est pas encore disponible. Consultez le site régulièrement pour voir les avancées!',
    logoutConfirmation: 'Êtes-vous sûr de vouloir vous déconnecter ?',
    noCV: 'Vous n\'avez pas encore envoyé de CV',
    checkEmail: 'J\'ai vérifié mon email',
    profile_pic: 'Photo de profil',
    uploadProfilePic: 'Formats acceptés: jpg, png',
    status: 'Statut',
    pending: 'En attente de validation',
    accepted: 'Accepté',
    refused: 'Refusé, veuillez envoyer un autre cv vidéo.',
    interested: 'Ça m\'intéresse!',
    notInterested: 'Ça ne m\'intéresse pas',
    noMoreJobs: 'Vous avez vu toutes les offres d\'emploi correspondant à votre profil. Revenez plus tard pour en voir davantage!',
    searching: 'En recherche d\'emploi',
    searchingText: 'En sélectionnant "Non", vous risquez de manquer l\'opportunité qu\'un employeur vous contacte!',
    yes: 'Oui',
    no: 'Non',
    'forgot-password': 'Mot de passe oublié?',
    completeProfile: 'Pour postuler à des offres d\'emploi, les champs "Code postal", "Ville", "Domaine de travail", "Type d\'emploi", "Disponibilités" et "CV" doivent être remplis.',
    clientMode: "Cliquer ici pour s'inscrire en tant qu'entreprise",
    candidateMode: "Cliquer ici pour s'inscrire en tant que candidat",
    delivery: 'Livraison',
    pickup: 'Ramassage',
    'acquisition-method': 'Méthode d\'acquisition',
    stores: 'Magasins',
    quantityInCart: 'Vous avez cet item {{ quantity }} fois dans votre panier.',
    addToCart: 'Ajouter au panier',
    cartEmpty: 'Votre panier est vide',
    subTotal: 'Sous-total',
    checkout: 'Passer à la caisse',
    itemsRemovedOOS: 'Ces items ont été enlevés de votre panier, car ils étaient hors de stock: ',
    itemsAdjusted: 'Votre demande était supérieur aux stocks pour les items: {{ items }}. Veuillez ajuster la quantité achetée en fonction du stock maximal.',
    oosortoomuch: 'Hors de stock/limite atteinte',
    noOrder: 'Aucune commande n\'a été trouvée pour ce numéro',
    orderNumber: 'Commande #{{ number }}',
    multipleOrdersNumber: 'Si il y a plusieurs numéros de commandes, c\'est parce que vous avez commandé de différents commerçant. Vous devez planifier le ramassage/livraison avec eux individuellement.',
    policies: 'Politiques de remboursement, livraison et ramassage',
    of: 'de',
    refunds: 'Remboursements',
    deliveryPickup: 'Livraison/Ramassage',
    bestSellers: 'Meilleurs vendeurs',
    newProducts: 'Nouveaux produits',
    noProductPage: 'Aucun produit disponible pour cette page...',
    next: 'Prochain',
    previous: 'Précédent',
    noProductFound: 'Le produit n\'existe pas ou a été supprimé.',
    managePictures: 'Gérer mes photos',
    addPictures: 'Ajouter des photos',
    numberPics: 'Nombre de photos que vous pouvez encore ajouter:',
    close: 'Fermer',
    sendSave: 'Envoyer et sauvegarder',
    filesErrors: 'Vérifiez que vous n\'avez pas dépassé la limite et que tous les fichiers sont bien des images',
    deleteImage: 'Êtes-vous sûr de vouloir supprimer cette image?',
    networkPending: 'En attente',
    networkRemove: 'Enlever du réseau',
    addNetwork: 'Ajouter au réseau',
    deleteRelationship: 'Supprimer cette relation? Cette action est irréversible.',
    manageNetwork: 'Gérer mon réseau',
    requestsReceived: 'Demandes reçues',
    relationships: 'Relations',
    requestsSent: 'Demandes envoyées',
    accept: 'Accepter',
    refuse: 'Refuser',
    cancel: 'Annuler',
    noDiscussions: "Vous n'avez pas sélectionné de discussion. Sélectionnez en une ou partez-en une nouvelle!",
    employer: 'Employeur',
    candidate: 'Candidat',
    sendMessage: 'Envoyer un message',
    thatsAll: 'Vous avez vu toutes les publications!',
    addPost: 'Ajouter une publication',
    text: 'Texte',
    video_link: 'Lien vers une vidéo YouTube',
    'images[]': 'Images (max 8)',
    manageBusiness: 'Gérer mon entreprise/boutique',
    createBusiness: 'Créer mon entreprise/boutique',
    business: 'Entreprise',
    comments: 'Commentaires',
    delete: 'Supprimer',
    edit: 'Modifier',
    copiedLink: 'Lien copié!',
    noPosts: 'Aucune publication!',
    signup: 'S\'inscrire',
    onboarding: "Connectez avec des professionnels et entreprises pour tout savoir sur les opportunités d'Affaires",
    remainingC: 'charactères restants',
    notifications: 'Notifications',
    notifText: '{{ name }} et {{ number }} autres ont commenté sous votre publication: ',
    notifDoubleText: '{{ name }} and {{ name2 }} ont commenté sous votre publication: ',
    notifSingleText: '{{ name }} a commenté sous votre publication: ',
    deliveryFees: 'Frais de livraison',
    product: 'produit',
    noDeliveryFees: 'Livraison gratuite!',
    feed: 'Actualité',
    inTheCart: 'dans le panier',
    variantsCheckout: 'Les options sélectionnées (taille, couleur, etc) ne sont pas affichées lors du passage à la caisse. Soyez toutefois assurés que vous aurez la bonne variante du produit.',
    answerSent: 'Vous avez envoyé votre réponse pour cette offre!',
    seeOtherJobs: "Voir les autres offres d'emploi.",
    editYourProfile: 'Si votre profil n\'est pas rempli, remplissez le pour voir des offres!',
    noPhoneEmail: 'Votre message ne peut pas contenir un email/numéro de téléphone.',
    clickPlus: 'Vous devez cliquer sur le bouton vert à côté de votre réponse pour l\'ajouter. Si le bouton à côté de votre réponse n\'est pas rouge, votre réponse n\'est pas envoyée.',
    freeDelivery: 'Livraison gratuite chez {{ company }} à partir de {{ minimum }}$ minimum commandé chez eux',
    bio: 'Titre professionnel',
    driver: 'Avez-vous un permis de conduire?',
    car: 'Avez-vous une voiture?',
    student_permit: 'Avez-vous un permis d\'étudiant?',
    work_permit: 'Avez-vous un permis de travail?',
    permanence: 'Avez-vous votre permanence?',
    citizen: 'Avez-vous la citoyenneté canadienne?',
    rebateToApply: 'Rabais à appliquer:',
    useCoupon: '(utiliser un coupon?)',
    usePromo: '(utiliser une promotion?)',
    findJobOffer: 'Trouver une offre d\'emploi',
    company: 'Entreprise',
    professional: 'Professionnel',
    discoverOffers: 'Découvrir toutes les offres disponibles selon votre profil de recherche',
    privacyPolicy: 'Politique de confidentialité',
    addJobOffer: 'Publier une offre d\'emploi',
    connectedAs: 'Connecté en tant que {{ name }}',
    switchTo: 'Passer au compte "{{ name }}"',
    needs: 'Besoins',
    setupPassword: 'Il est maintenant temps de choisir un mot de passe.',
    success: 'Succès!',
    passwordSetupDone: 'Le mot de passe a bien été enregistré!',
    affiliation: "Réfère & obtiens 25$!",
    oldJobOffers: "Offres d'emplois auxquelles j'ai postulé",
    oldJobOffersBanner: "Cliquez ici pour revoir toutes les offres d'emploi auxquelles vous avez postulé!",
    affiliationMontRose: "Vous reçevrez 25$ pour chaque candidat référé embauché par cette entreprise.",
    affiliationDataText: "Pour référer un candidat, il suffit d'ajouter '?ref={{code}}' à la fin d'une url de ALPE. (e.g. alpeconnexion.com/?ref={{code}}). Votre lien a été cliqué {{visits}} fois depuis votre inscription le {{signupDate}}.",
    affiliationSignedUpItem: "{{name}} s'est incrit.e le {{date}}",
    affiliationHiredItem: "{{name}} a été embauché.e le {{date}}",
    affiliationData: "Informations générales",
    affiliationSignedUp: "Inscriptions",
    affiliationHired: "Embauches",
    affiliationPayout: "Paiement",
    affiliationPayoutExplanation: "ALPE utilise PayPal pour payer les affiliés. Vous avez gagné {{ total }}$ depuis le début, retiré {{ withdrawn }}$ et il vous reste {{ available }}$ à retirer.",
    event: "Évènement",
    amount: "Montant",
    date: "Date",
    fundsCleared: "Fonds disponibles",
    payout: "Paiement",
    affiliationEmail: "Veuillez entrer l'adresse email que vous utilisez pour votre compte PayPal.",
    withdrawal: "Retrait de vos gains",
    terms: "J'accepte les conditions d'utilisation",
    serverError: "Il y a eu une erreur serveur. Retour vers la page d'accueil.",
    notFound: "Cette page n'existe pas. Retour vers la page d'accueil.",
    modificationsSaved: "Les modifications ont bien été sauvegardées!",
    start: "Débuter",
    linkToShareForAffiliation: "Lien à partager pour la référence",
    cvNotif: "Vous devez remplir votre CV!",
    cvVideoNotif: "Vous devez créer votre CV Vidéo!",
    publicationsLiked: "Publications aimées",
    history: "Historique"
  },
  signup: {
    introduction: "ALPE a mis en place un processus d'inscription sophistiqué qui ciblera vos intérêts et vos besoins afin de vous donner une expérience complète et personnalisée.",
    introductionFirst: "Que tu sois salarié, travailleur autonome, entrepreneur, commerçant ou même artiste, ALPE est la façon la plus simple de connecter avec les professionnels et entreprises d'ici.",
    first_name: "Débutons les présentations! Quel est votre prénom?",
    last_name: "Et votre nom de famille?",
    phone_number: "À quel numéro de téléphone pouvez-vous être rejoint?",
    postal: "Quel est votre code postal?",
    city: "Où êtes-vous localisé?",
    bio: "Décrivez votre titre professionel",
    profesional: "Je suis un candidat",
    company: "Je suis une compagnie",
    email: "Quel est votre adresse email?",
    email_confirmation: "Veuillez réécrire le email précédemment entré.",
    connections: "Je cherche à connecter avec...",
    interests: "Quels sont vos intérêts? Qu'est-ce qui vous passionne?",
    field_of_work: "Quels sont vos domaines de travail?",
    candidate_profile: "Maintenant, nous vous poserons quelques questions sur votre vie professionnelle.",
    salary_id: "Quelles sont vos attentes salariales?",
    availability: "Quand êtes-vous disponible pour travailler?",
    job_type: "Quel type d'emploi vous intéresse?",
    training: "Quelles sont vos formations?",
    work_experiences: "Quelles sont vos expériences de travail?",
    driver: "Avez-vous un permis de conduire?",
    car: "Avez-vous un véhicule?",
    student_permit: "Avez-vous un permis d'étudiant?",
    work_permit: "Avez-vous un permis de travail?",
    permanence: "Avez-vous votre permanence?",
    citizen: "Êtes-vous un citoyen canadien?",
    cv: "Veuillez déposer votre CV.",
    video_cv: "Nous allons construire votre CV vidéo ensemble. Vous aurez 30 secondes pour répondre à chacune des questions. La question se retrouve en haut. Si votre caméra s'affiche, vous pouvez enregistrer directement sur le site. Sinon, veuillez déposer un fichier vidéo de maximum 30 secondes où vous répondez à la question.",
    video_cv_onboarding: "Prenez de l'avance, faites votre entrevue maintenant!",
    password: "Quel sera votre mot de passe?",
    password_confirmation: "Veuillez confirmer votre mot de passe.",
    type: "Qui êtes-vous?",
    first_question: "Parle-nous de toi, qui es-tu?",
    second_question: "Quelles sont tes motivations?",
    third_question: "Où te vois-tu dans les cinq prochaines années?",
    fourth_question: "L'expérience que tu as le plus aimé et celle que tu as le moins aimé?",
    fifth_question: "Pourquoi es-tu le candidat que l'employeur devrait choisir?",
    startCapture: "Commencer l'enregistrement",
    stopCapture: "Arrêter l'enregistrement",
    playback: "Voir la vidéo",
    record: "Retour à l'enregistrement",
    countdownDone: "L'enregistrement est fini. Regardez le résultat!",
    secondsRemaining: "secondes restantes",
    full_name: "Nom complet",
    affiliation: "Le programme d'affiliation de ALPE vous premet d'inviter des candidats facilement sur le site et de recevoir de l'argent une fois qu'ils sont embauchés par une entreprise. Vous reçevez votre argent via PayPal.",
    code: "Choisissez un code d'invitation personnalisé composé de charactères alphanumériques qui se retrouvera dans le lien à partager.",
    video_cv_file: "Veuillez répondre à ces questions dans une vidéo de moins de 3 minutes.",
    restart: "Recommencer",
    cvChances: 'Remplir votre CV et votre CV vidéo augmente vos chances de vous faire recruter!',
    fillCV: 'Remplir mon CV',
    fillVideoCV: 'Remplir mon CV vidéo',
    backHome: "Retourner à l'accueil",
    explanations: "Votre profil est maintenant complet! Vous pouvez désormais profiter pleinement de l'expérience de ALPE.",
    cvChoice: 'Voulez-vous télécharger votre CV ou le créer?',
    uploadCV: 'Télécharger mon CV',
    createCV: 'Créér mon CV',
    uploadVideoCV: 'Télécharger mon CV Vidéo',
    video_cv_upload_file: 'Télécharger mon CV Vidéo',

    createVideoCV: 'Créer mon CV Vidéo',
  },
  employers: {
    coffeeMachine: 'Plateforme sociale',
    business: 'Plateforme business',
    choice: 'Veuillez choisir où vous souhaitez aller'
  }
};

export default fr;
