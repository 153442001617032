import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ProductMedias from './ProductMedias';
import cart from 'img/icons/cart-black.svg';
import { getCartVariant, addToCartVariant, changeMode, getMode, getQuantityFromCartVariant, getAmountInCartVariant, objt, getModeVariant, changeModeVariant } from './../../utils';

export class VariantProductBox extends Component {
  constructor (props) {
    super(props);
    this.state = { options: {}, loading: true };
  }

  componentDidMount () {
    const { product } = this.props;
    product.variants.options.forEach(v => {
      this.changeOption(v.id, v.options[0].id);
    });
    this.setState({ loading: false });
  }

  changeOption (specId, optionId) {
    this.setState(function (prevState, props) {
      prevState.options[specId] = parseInt(optionId);
    });
    this.forceUpdate();
  }

  getYoutubeLink () {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = this.props.job.youtube_link.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
  }

  getAcquisitionMethods () {
    var methods = [];
    if (this.props.product.delivery === 'true') {
      methods.push(this.props.t('delivery'));
    }
    if (this.props.product.pickup === 'true') {
      methods.push(this.props.t('pickup'));
    }
    return methods.join(', ');
  }

  getCart () {
    if (getQuantityFromCartVariant(this.props.product.id) > 0) {
      return (<div className="tw-mt-2 tw-text-white tw-px-6 tw-py-4 tw-border-0  tw-relative tw-mb-4 tw-bg-green-500">
        <span className="tw-inline-block tw-align-middle tw-mr-8">
          { this.props.t('quantityInCart', { quantity: getQuantityFromCartVariant(this.props.product.id) })}
        </span>
        {this.getModeChange()}
      </div>);
    }
    return null;
  }

  getDeliveryFee () {
    const { product } = this.props;
    if (product.delivery_fees && product.delivery_fees != 0) {
      return <span>{this.props.t('deliveryFees')}: {product.delivery_fees}$ / {this.props.t('product')}</span>;
    } else {
      return <span>{this.props.t('noDeliveryFees')}</span>;
    }
  }

  getVariant () {
    const { product } = this.props;
    return product.variants.variants.filter((value) => {
      var valid = true;
      value.options.forEach(s => {
        if (this.state.options[s.spec_id] != s.id) {
          valid = false;
        }
      });
      return valid;
    })[0];
  }

  getModeChange () {
    const { product } = this.props;
    return (
      <div>
        <div className="tw-flex tw-flex-row">
          <span className="tw-font-bold">{this.props.t('acquisition-method')}:</span>
          <div>
            {product.delivery === 'true' &&
            <label className="tw-inline-flex tw-items-center tw-ml-6">
              <input defaultChecked={getMode(product.id) === 'delivery'} onChange={() => { changeMode(product.id, 'delivery'); this.forceUpdate(); }} type="radio" name="mode" value="delivery"></input>
              <span className="tw-ml-1">{ this.props.t('delivery') }</span>
            </label>}
            {product.pickup === 'true' &&
            <label className="tw-inline-flex tw-items-center tw-ml-6">
              <input defaultChecked={getMode(product.id) === 'pickup'} onChange={() => { changeMode(product.id, 'pickup'); this.forceUpdate(); }} type="radio" name="mode" value="pickup"></input>
              <span className="tw-ml-1">{ this.props.t('pickup') }</span>
            </label>}
          </div>
        </div>
        <p>{getMode(product.id) === 'delivery' && this.getDeliveryFee()}</p>
      </div>
    );
  }

  addToCart (e) {
    const { product } = this.props;
    // Créer une fonciton "addToCartVariant"
    // accepte le product id et un objet avec les specs + options
    addToCartVariant(this.getVariant().id);
    if (getModeVariant(product.variant_id) === null) {
      changeModeVariant(product.variant_id, this.getDefaultMode());
    }
    this.forceUpdate();
    this.props.cartUpdate(getAmountInCartVariant());
    e.target.blur();
  }

  getDefaultMode () {
    const { product } = this.props;
    if (product.delivery === 'true') {
      return 'delivery';
    } else {
      return 'pickup';
    }
  }

  generateOptions (option) {
    return (
      <>
        <div className="mb-2">
          <label>{objt(option)}</label>
          <select onChange={(e) => this.changeOption(option.id, e.target.value)} name={option.id} className="tw-w-full tw-text-black mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
            {option.options.map(i => (
              <option value={i.id} key={i.id}>{objt(i)}</option>
            ))}
          </select>
        </div>
      </>
    );
  }

  getCartQuantities () {
    const cart = getCartVariant();
    if (cart !== null) {
      return (<div>
        {Object.keys(cart).map(k => {
          if (this.getVariantFromId(k) !== undefined) {
            return <p>{getQuantityFromCartVariant(k)}x {this.getVariantFromId(k).name} {this.props.t('inTheCart')}</p>;
          }
        })}
        {this.getModeChange()}
      </div>);
    }
  }

  getVariantFromId (id) {
    const { variants } = this.props.product.variants;
    return variants.filter(v => {
      return v.id == id;
    })[0];
  }

  render () {
    const { product, images, employer } = this.props;
    const { options } = product.variants;
    if (this.state.loading == true) {
      return null;
    }
    return (
      <div className="col-lg-8 tw-h-64">
        <div className="tw-card">
          {Object.values(product.rebates).map(r => (
            <div key={r.id} className="tw-bg-green-100 tw-mt-2 tw-mb-2 tw-border-l-4 tw-border-green-500 tw-text-green-700 tw-p-2 tw-text-sm">
              <p>{r.name} - {r.amount} {r.reduction_type == 'percent' ? '%' : '$'}</p>
            </div>
          ))}

          <ProductMedias employer={employer} product={product} images={images} />
          {employer.free_delivery_threshold && <div className="tw-flex tw-justify-center">
            <p className="tw-font-bold tw-text-center">{this.props.t('freeDelivery', { company: employer.name, minimum: employer.free_delivery_threshold })}</p>
          </div>}
          <div className="row tw-items-start tw-justify-between">
            <div className="col-lg-6 tw-flex tw-flex-col tw-h-full">
              <span className="tw-font-bold tw-text-2xl">{product.title}</span>
              <span className="tw-font-semibold">{parseFloat(product.price).toFixed(2)} $</span>
              <p className="tw-my-4 tw-whitespace-pre-line">{product.description}</p>
              <p className="tw-font-semibold">{this.props.t('acquisition-method')}: {this.getAcquisitionMethods()}</p>
            </div>
            <div className="col-lg-6">
              {options.map(option => {
                return this.generateOptions(option);
              })}
              <p>{this.getVariant().name}</p>

              <button onClick={(e) => { this.addToCart(e); }} className="tw-ml-auto focus:tw-bg-yellow-700 tw-bg-aa-yellow tw-px-3 tw-py-2 tw-font-bold  tw-text-sm tw-m-1 tw-self-end tw-leading-none tw-min-w-32 tw-text-black tw-cursor-pointer tw-flex tw-flex-row focus:tw-outline-none tw-items-center">
                <img src={cart} alt="Icon" className="tw-h-8 tw-mr-2" />
                {this.props.t('addToCart')}
              </button>
              {this.getCartQuantities()}

            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(VariantProductBox);
