import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { formToFormData } from 'utils';
import Screen from 'components/templates/Screen.js';
import PropTypes from 'prop-types';
import instance from 'axiosInstance';
import TextInput from 'components/forms/TextInput';
import Form from 'components/forms/Form';
import FileInput from 'components/forms/FileInput';
import Submit from 'components/forms/Submit';
import { connect } from 'react-redux';
import { addErrors, handleChange, clearForm } from 'redux/actions';
import { withRouter } from 'react-router-dom';
import TextareaInput from 'components/forms/TextareaInput';

export class UpdatePost extends Component {
  constructor () {
    super();
    this.state = { loading: true };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount () {
    this.getPostWithComments();
  }

  getPostWithComments () {
    instance.get('/posts/' + this.props.match.params.id).then(s => {
      this.setState({ post: s.data.post }, () => {
        this.props.handleChange('text', s.data.post.text, 'modify-post');
        this.props.handleChange('video_link', s.data.post.video_link, 'modify-post');
        this.forceUpdate();
        this.setState({ loading: false });
      });
    });
  }

  handleSubmit (e) {
    e.preventDefault();
    this.setState({ loading: true });
    var fd = formToFormData(['text', 'video_link', 'images[]'], this.props.form);
    instance.post('/posts/' + this.state.post.id + '/update', fd).then((success) => {
      this.props.clearForm('modify-post');
      this.props.history.push('/profile/' + this.state.post.candidate_id + '/posts/' + this.state.post.id);
    }, (error) => {
      this.props.addErrors(error.response.data.errors, 'modify-post');
    }).then(() => {
      this.setState({ loading: false });
    });
  }

  render () {
    const { t } = this.props;
    return (
      <Screen loading={this.state.loading} type="triangle" title={this.props.t('pages:modifyPost')}>
        <div className="container">
          <div className="row">
            {this.state.loading === false &&
            <Form onSubmit={this.handleSubmit} name="modify-post" classes="col-lg-8 tw-h-64 tw-mx-auto" classesOverwrite={true}>
              <div className="tw-card tw-mt-3 tw-relative tw-z-50">
                <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
                  <h2 className="tw-text-xl tw-font-bold">{t('pages:modifyPost')}</h2>
                </div>
                <TextareaInput name="text" />
                <TextInput required="false" name="video_link" />
                <FileInput required="false" name="images[]" multiple={true} />
                <div className="tw-flex tw-flex-row tw-justify-end tw-py-3">
                  <Submit />
                </div>
              </div>
            </Form>}
          </div>

        </div>
      </Screen>
    );
  }
}

UpdatePost.propTypes = {
  t: PropTypes.func.isRequired
};

UpdatePost.defaultProps = {
  form: {}
};

const mapStateToProps = ({ input }) => {
  return {
    form: input['modify-post']
  };
};

export default withRouter(connect(mapStateToProps, { addErrors, handleChange, clearForm })(withTranslation('common')(UpdatePost)));
