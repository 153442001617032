import React, { Component } from 'react';

export class Gateway extends Component {
  render () {
    return (
      <div className={'tw-w-full tw-h-full tw-cursor-pointer tw-text-white tw-text-xl tw-font-bold' + (' bg-' + this.props.img)} onClick={this.props.action}>
        <div className="tw-w-full tw-h-full tw-flex tw-flex-row tw-justify-center tw-items-center" style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
          <span>{this.props.text}</span>
        </div>
      </div>
    );
  }
}
