import React, { Component } from 'react';
import profile from 'img/placeholder/profile.svg';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import instance from 'axiosInstance';
import { ReactLoading } from 'react-loading';

export class NetworkRow extends Component {
  constructor () {
    super();
    this.state = { loading: false };
    this.decision = this.decision.bind(this);
  }

  getProfilePic () {
    const { candidates, candidateId } = this.props;
    if (!candidates[candidateId].profile_pic) {
      return profile;
    }
    return (process.env.REACT_APP_STORAGE_URL + candidates[candidateId].profile_pic).replace(/([^:]\/)\/+/g, '$1');
  }

  removeRequest () {
    if (window.confirm(this.props.t('deleteRelationship'))) {
      var fd = new FormData();
      this.props.setLoading();
      fd.append('receiver_id', this.props.candidateId);
      instance.post('/relation-request/delete', fd).then(() => {
        this.props.update();
      }, () => {
        this.props.update();
      });
    }
  }

  decision (id, decision) {
    if (decision === 'accepted' || window.confirm(this.props.t('deleteRelationship'))) {
      var fd = new FormData();
      this.props.setLoading();
      fd.append('receiverDecision', decision);
      instance.post('/relation-request/' + this.props.relId, fd).then(() => {
        this.props.update();
      }, () => {
        this.props.update();
      });
    }
  }

  startDiscussion () {
    this.props.setLoading();
    instance.get('/messages/discussions/new/' + this.props.candidateId).then(() => {
      this.props.history.push('/messaging');
    });
  }

  render () {
    const { candidates, candidateId, relId } = this.props;
    return (
      <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
        {this.state.loading ? <ReactLoading />
          : <>
            <div onClick={() => { this.props.history.push('/profile/' + candidateId); }} className="tw-flex tw-flex-row tw-justify-start tw-items-center tw-cursor-pointer">
              <div alt="Profile Picture" className=" tw-h-12 tw-w-12 tw-bg-cover tw-bg-center tw-mr-2">
                <img src={ this.getProfilePic() } className="tw-w-full tw-h-full tw-object-cover" />
              </div>
              <span>
                { candidates[candidateId].first_name }
              </span>
            </div>
            <div className="tw-flex tw-flex-row tw-space-x-2">
              {this.props.type === 'relationship' &&
          <div className="tw-bg-green-500 tw-h-3/4 tw-flex tw-flex-row tw-justify-center tw-items-center tw-px-2  tw-py-1 tw-cursor-pointer hover:tw-bg-green-700" onClick={() => { this.startDiscussion(); }} >
            <span>{this.props.t('sendMessage')}</span>
          </div>}
              {this.props.type === 'received' &&
          <div className="tw-bg-green-500 tw-h-3/4 tw-flex tw-flex-row tw-justify-center tw-items-center tw-px-2  tw-py-1 tw-cursor-pointer hover:tw-bg-green-700" onClick={() => { this.decision(relId, 'accepted'); }} >
            <span>{this.props.t('accept')}</span>
          </div>}
              {(this.props.type === 'received') ? <div className="tw-bg-red-500 tw-h-3/4 tw-flex tw-flex-row tw-justify-center tw-items-center tw-px-2  tw-py-1 tw-cursor-pointer hover:tw-bg-red-700" onClick={() => { this.decision(relId, 'refused'); }} >
                <span>{this.props.t('refuse')}</span>
              </div> : <div className="tw-bg-red-500 tw-h-3/4 tw-flex tw-flex-row tw-justify-center tw-items-center tw-px-2  tw-py-1 tw-cursor-pointer hover:tw-bg-red-700" onClick={() => { this.removeRequest(); }} >
                <span>{this.props.t('cancel')}</span>
              </div>
              }
            </div>
          </>
        }
      </div>
    );
  }
}

export default withTranslation('common')(withRouter(NetworkRow));
