import React from 'react';
import ReactDOM from 'react-dom';
import 'css/main.css';
import App from 'App';
import './i18n';
import { Provider } from 'react-redux';
import store from './redux/store';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SENTRY_RELEASE) {
  Sentry.init({
    dsn: "https://eb9f3fd772b547a3915cf09334b0f694@o926224.ingest.sentry.io/5875448",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5,
    release: process.env.REACT_APP_SENTRY_RELEASE,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
