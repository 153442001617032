import { connect } from 'react-redux';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import close from 'img/icons/close.svg';
import { languageDefined } from 'utils';
import Button from './Button';

export class LangPrompt extends Component {
  render () {
    if(!languageDefined()) {
      return (
        <div class="tw-fixed tw-inset-0 tw-overflow-y-auto" style={{zIndex: 1000}} aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="tw-flex tw-items-center tw-justify-center tw-min-h-screen tw-pt-4 tw-px-4 tw-pb-20 tw-text-center sm:tw-block sm:tw-p-0">
          <div class="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" aria-hidden="true"></div>

          <span class="tw-hidden sm:tw-inline-block sm:tw-align-middle sm:tw-h-screen" aria-hidden="true">&#8203;</span>
          <div class="tw-inline-block tw-align-bottom tw-bg-white tw-rounded-lg tw-px-4 tw-pt-5 tw-pb-4 tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all sm:tw-my-8 sm:tw-align-middle sm:tw-max-w-lg sm:tw-w-full sm:tw-p-6">
            <div>
              <div class="mt-3 text-center sm:mt-5">
                <h3 class="tw-text-center tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900" id="modal-title">
                  Choisir votre langue / Pick your language
                </h3>
              </div>
            </div>
            <div class="tw-mt-5 sm:tw-mt-6 sm:tw-grid sm:tw-grid-cols-2 sm:tw-gap-3 sm:tw-grid-flow-row-dense">
              <Button text="Continuer en Français" action={() => {
                    this.props.i18n.changeLanguage('fr');
                    window.localStorage.setItem('i18nextLng', 'fr');                  
              }} />
              <Button text="Continue in English" action={() => {
                    this.props.i18n.changeLanguage('en');
                    window.localStorage.setItem('i18nextLng', 'en');                  
              }} />
            </div>
          </div>
        </div>
      </div>

      );
    }
    return null;
  }
}

const mapStateToProps = ({ overlay }) => {
  return {
    overlay: overlay.overlay,
    forceOpen: overlay.forceOpen
  };
};

export default connect(mapStateToProps, null)(withTranslation('common')(withRouter(LangPrompt)));
