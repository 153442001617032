

import React, { Component } from 'react';
import { isAuth, objt } from 'utils';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

export class OldJobOffers extends Component {

  render () {
    if(isAuth()) {
      return (
        <div class={"tw-flex tw-flex-row tw-justify-start tw-flex-wrap " + this.props.additionalClasses}>
        <button {...this.props} onClick={() => this.props.history.push('/seen-job-offers') } className="tw-text-center tw-w-full focus:tw-bg-gray-900 bg-full-gray tw-px-3 tw-py-2 tw-font-bold  tw-text-sm tw-m-1 tw-leading-none tw-min-w-32 tw-text-white tw-cursor-pointer focus:tw-outline-none ">
            {this.props.t('oldJobOffersBanner')}
        </button>        
        </div>
      );  
    }
    return null;
  }
}

export default withTranslation(['common'])(withRouter(OldJobOffers));
