import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withTranslation } from 'react-i18next';
import { isAuth, utilsOpenPopup } from 'utils';
import Button from 'components/common/Button';
import Screen from 'components/templates/Screen.js';
import PropTypes from 'prop-types';
import instance from 'axiosInstance';
import { withRouter } from 'react-router-dom';
import TextInput from 'components/forms/TextInput';
import Form from 'components/forms/Form';
import Submit from 'components/forms/Submit';
import { addErrors, handleChange  } from 'redux/actions';
import { connect } from 'react-redux';
import * as Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';

export class Affiliation extends Component {

  constructor (props) {
    super(props);
    this.state = { data: {}, candidates: [], hirees: [], financial: {}, events: [], loading: true };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount () {
    if(isAuth()) {
      instance.get('/aff/data').then((s) => {
        if(s.data.affiliationData == 'NO_DATA') {
          Cookies.set('affiliated', false);
          this.props.history.push('/');
          utilsOpenPopup('affiliation');    
        } else {
          this.setState({ data: s.data.affiliationData, signupDate: s.data.signupDate, hirees: s.data.hirees, candidates: s.data.candidates, financial: s.data.financial, events: s.data.events, loading: false });
          this.props.handleChange('paypal_email', s.data.affiliationData.model.email, 'withdraw');  
        }
      }, (error) => {
        Cookies.set('affiliated', false);
        this.props.history.push('/');
      });
    } else {
      this.props.history.push('/');
      utilsOpenPopup('noAuthAffiliate');
    }
    
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({loading: true});
    var fd = new FormData;
    fd.append('paypal_email', this.props.form.paypal_email);
    instance.post('/aff/request', fd).then((success) => {
      window.location.reload();
    }, (error) => {
      this.props.addErrors(error.response.data.errors, 'withdraw');
    });

  }


  render () {
    const { t } = this.props;
    const { data, candidates, hirees, financial, events } = this.state;
    return (
      <Screen type="triangle" loading={this.state.loading} title={this.props.t('pages:affiliation')}>
          <Helmet>
        <title>AVA - Affiliation</title>
      </Helmet>

        <div className="container tw-space-y-3">
          <div className="row">
            <div className="col-lg-6 tw-mx-auto">
              <div className="tw-card">
                <h2 className="tw-text-xl tw-font-bold">{t('affiliationData')}</h2>
                <p>{ this.props.t('affiliationDataText', {code: data?.model?.code, visits: data?.model?.visits, signupDate: data?.signupDate}) }</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 tw-mx-auto">
              <div className="tw-card">
                <h2 className="tw-text-xl tw-font-bold">{t('affiliationSignedUp')}</h2>
                <ul className="tw-list-disc tw-ml-4">
                  {candidates.map(candidate => <li>{t('affiliationSignedUpItem', {name: candidate.full_name, date: candidate.created_at})}</li>)}
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 tw-mx-auto">
              <div className="tw-card">
                <h2 className="tw-text-xl tw-font-bold">{t('affiliationHired')}</h2>
                <ul className="tw-list-disc tw-ml-4">
                  {hirees.map(hiree => <li>{t('affiliationHiredItem', {name: hiree.full_name, date: hiree.created_at})}</li>)}
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 tw-mx-auto">
              <div className="tw-card">
                <h2 className="tw-text-xl tw-font-bold">{t('affiliationPayout')}</h2>
                <p onClick={this.payout}>{t('affiliationPayoutExplanation', {available: financial.available, withdrawn: financial.withdrawn, total: financial.total})}</p>
                <table className="tw-table-auto tw-w-full tw-mt-1 tw-text-center">
                  <thead>
                    <th>{t('event')}</th>
                    <th>{t('amount')}</th>
                    <th>{t('date')}</th>
                  </thead>
                  <tbody>
                    {Object.values(events).map(e => (
                      <tr>
                        <td>{e.type == 'hiring' ? t('fundsCleared') : t('payout')}</td>
                        <td className={e.amount > 0 ? "tw-text-green-500" : "tw-text-red-500"}>{e.amount}$</td>
                        <td>{e.date}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 tw-mx-auto">
              <div className="tw-card">
                <h2 className="tw-text-xl tw-font-bold">{t('withdrawal')}</h2>
                <p>{ t('affiliationEmail') }</p>
                <Form name="withdraw" onSubmit={this.handleSubmit}>
                  <TextInput name="paypal_email" noLabel={true} />
                  <Submit />
                </Form>
              </div>
            </div>
          </div>

        </div>

      </Screen>
    );
  }
}

Affiliation.propTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ input }) => {
  return {
    form: input['withdraw']
  };
};

export default connect(mapStateToProps, { addErrors, handleChange })(withRouter(withTranslation('common')(Affiliation)));
