import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormContext from 'context/FormContext';
import { connect } from 'react-redux';

export class Form extends Component {
  constructor () {
    super();
    this._handleKeyDown = this._handleKeyDown.bind(this);
  }

  _handleKeyDown (e) {
    if (this.props.submitOnEnter === false) {
      if (e.key === 'Enter') {
        e.preventDefault();
        return false;
      }
    } else {
      document.getElementById('blurHandler').blur();
    }
  }

  getClasses () {
    if (this.props.classesOverwrite && this.props.classesOverwrite === true) {
      return this.props.classes;
    } else {
      return 'tw-flex tw-flex-col ' + this.props.classes;
    }
  }

  render () {
    return (
      <FormContext.Provider value={{ name: this.props.name }}>
        <form onKeyDown={this._handleKeyDown} onSubmit={this.props.onSubmit} className={this.getClasses()}>
          {this.props.children}
          <div id="blurHandler" className="tw-hidden" tabIndex="-1" />
        </form>
        {this.props.errors && this.props.errors.form && (
          <div className="tw-bg-red-100 tw-mt-2 tw-border-l-4 tw-border-red-500 tw-text-red-700 tw-p-2 tw-text-sm">
            <p>{this.props.errors.form}</p>
          </div>
        )}
      </FormContext.Provider>
    );
  }
}

Form.propTypes = {
  children: PropTypes.array.isRequired,
  classes: PropTypes.string,
  onSubmit: PropTypes.func,
  name: PropTypes.string.isRequired
};

Form.defaultProps = {
  classes: ''
};

const mapStateToProps = ({ errors }, ownProps) => {
  return {
    errors: errors[ownProps.name]
  };
};

export default connect(mapStateToProps, null)(Form);
