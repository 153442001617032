import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Screen from 'components/templates/Screen.js';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import instance from 'axiosInstance';
import { isCartEmpty, addToCart, getModes, getAmountInCart, changeMode, getMode, isCartVariantEmpty, changeModeVariant, getModeVariant, getModesVariant, deleteFromCartVariant } from 'utils';
import { getCart, getQuantityFromCart, removeFromCart, deleteFromCart, getCartVariant, getQuantityFromCartVariant, removeFromCartVariant, addToCartVariant } from './../../utils';
import { loadStripe } from '@stripe/stripe-js';
import * as Cookies from 'js-cookie';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);
export class Cart extends Component {
  constructor (props) {
    super(props);
    this.state = { coupon: false, loading: true, items: [], rebate: null, removed: [], adjusted: [], price: 0, empty: false, companies: [], rebates: [] };
  }

  getData () {
    if (isCartEmpty() && isCartVariantEmpty()) {
      this.setState({ empty: true, loading: false });
    } else {
      var fd = new FormData();
      fd.append('items', JSON.stringify(getCart()));
      fd.append('variants', JSON.stringify(getCartVariant()));
      instance.post('/products/cart', fd).then((s) => {
        this.setState({ loading: false, couponValue: '', items: s.data.products, removed: s.data.removed, adjusted: s.data.adjusted, price: s.data.price, companies: s.data.companies, rebates: s.data.rebates });
      });
    }
  }

  componentDidMount () {
    this.getData();
  }

  cartHasItems () {
    var cart = getCart();
    var variants = getCartVariant();
    if (cart !== null || variants !== null) {
      if (cart == null) {
        cart = {};
      } else if (variants == null) {
        variants = {};
      }
      return Object.values(cart).filter(v => v > 0).length !== 0 || Object.values(variants).filter(v => v > 0).length !== 0;
    } else {
      return false;
    }
  }

  getTotal () {
    if (this.state.items.length === 0) {
      return 0;
    }
    var total = 0;
    var cart = getCart();
    var variants = getCartVariant();
    if (cart == null) {
      cart = {};
    } else if (variants == null) {
      variants = {};
    }
    Object.keys(cart).forEach(key => {
      if (this.state.items.find(item => item.id === parseInt(key)) !== undefined) {
        total += this.state.items.find(item => item.id === parseInt(key)).price * cart[key];
      }
    });
    Object.keys(variants).forEach(key => {
      if (this.state.items.find(item => item.variant_id === parseInt(key)) !== undefined) {
        total += this.state.items.find(item => item.variant_id === parseInt(key)).price * variants[key];
      }
    });
    return total;
  }

  initCheckout (event) {
    const init = async () => {
      const stripe = await stripePromise;

      var fd = new FormData();
      fd.append('items', JSON.stringify(getCart()));
      fd.append('variants', JSON.stringify(getCartVariant()));
      fd.append('modes', JSON.stringify(getModes()));
      fd.append('modesVariants', JSON.stringify(getModesVariant()));
      fd.append('rebate', this.state.rebate);
      if(this.state.coupon === true || this.state.rebates.length === 0) {
        fd.append('coupon', this.state.couponValue);
      }
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/products/cart/checkout',
        {
          method: 'POST',
          body: fd,
          headers: {
            Authorization: 'Bearer ' + Cookies.get('auth')
          }
        });

      const session = await response.json();
      if (session?.updateCart && session.updateCart === true) {
        alert('Certains articles n\'étaient plus en stock, nous avons rafraîchi votre panier');
        window.location.reload();
        return false;
      }
      const result = await stripe.redirectToCheckout({
        sessionId: session.sessionId
      });

      if (result.error) {
        alert(result.error.message);
      }
    };
    init();
  }

  getRemoved () {
    if (this.state.removed.length > 0) {
      this.state.removed.forEach(r => {
        if (r.variant == true) {
          deleteFromCartVariant(r.variant_id);
        } else {
          deleteFromCart(r.id);
        }
      });
      return (<div className="tw-mt-2 tw-text-white tw-px-6 tw-py-4 tw-border-0  tw-relative tw-mb-4 tw-bg-red-500">
        <span className="tw-inline-block tw-align-middle tw-mr-8">
          { this.props.t('itemsRemovedOOS') }
          { this.state.removed.map(i => <span key={i.id} className="hover:tw-underline tw-cursor-pointer" onClick={() => { this.props.history.push('/products/' + i.id); }}> {i.title} </span>)}
        </span>
      </div>);
    }
  }

  getAdjusted () {
    if (this.state.adjusted.length > 0) {
      return (<div className="tw-mt-2 tw-text-black tw-px-6 tw-py-4 tw-border-0  tw-relative tw-mb-4 tw-bg-yellow-500">
        <span className="tw-inline-block tw-align-middle tw-mr-8">
          { this.props.t('itemsAdjusted', { items: this.state.adjusted.map(i => i.title) })}
        </span>
      </div>);
    }
  }

  getProducts () {
    var items;
    if (this.state.items.length > this.state.adjusted.length) {
      items = this.state.items.concat(this.state.adjusted);
    } else {
      items = this.state.adjusted.concat(this.state.items);
    }

    if(!(items === undefined)) {
      items.forEach(i => {
        if(getMode(i.id) === null) {
          if(i.delivery === 'true' && i.pickup === 'true') {
            changeMode(i.id, 'pickup');
          } else if(i.delivery === 'true') {
            changeMode(i.id, 'delivery');
          } else if(i.pickup === 'true') {
            changeMode(i.id, 'pickup');
          }
        }
      })
    }

    return items; 
  }

  getDeliveryFee (item) {
    const product = item;
    if (product.delivery_fees && product.delivery_fees != 0) {
      return <span>{this.props.t('deliveryFees')}: {product.delivery_fees}$ / {this.props.t('product')}</span>;
    } else {
      return <span>{this.props.t('noDeliveryFees')}</span>;
    }
  }

  getFreeDeliveries () {
    var companies = this?.state?.companies;
    if(typeof companies === 'object' && companies !== null) {
      companies = Object.values(companies);
    }

    var validCompanies = [];

    this.getProducts().forEach(item => {
      if ((item.variant == false || item?.variant === undefined) && getQuantityFromCart(item.id) === 0) {
        return null;
      } else if (item.variant == true && getQuantityFromCartVariant(item.variant_id) === 0) {
        return null;
      } else {
        validCompanies.push(item.employer.id);
      }
    });

    return companies.map(company => {
      if (company.free_delivery_threshold && validCompanies.includes(company.id)) {
        return <p key={company.id}>{this.props.t('freeDelivery', { company: company.name, minimum: company.free_delivery_threshold, interpolation: { escapeValue: false } })}</p>;
      }
      return null;
    });
  }

  handleRebateChange (e) {
    this.setState({ rebate: e.target.value });
  }

  handleCouponChange (e) {
    this.setState({ couponValue: e.target.value });
  }

  getRebates () {
    if (this.state.rebates.length !== 0 && this.state.coupon === false) {
      return (
        <div className="tw-flex tw-flex-col">
          <label className="tw-font-bold">{this.props.t('rebateToApply')}</label><span onClick={() => this.setState({ coupon: true })}className="tw-underline tw-cursor-pointer tw-text-blue-500 tw-text-sm">{this.props.t('useCoupon')}</span>
          <select className="tw-text-black tw-w-1/3" onChange={(e) => { this.handleRebateChange(e); }} name="rebate">
            <option value={null}></option>
            {this.state.rebates.map(rebate => (
              <option value={rebate.id} key={rebate.id}>
                {rebate.name} {this.props.t('of')} {rebate.employer.name}
              </option>
            ))}
          </select>
        </div>
      );
    } else if (this.state.coupon === true || this.state.rebates.length === 0) {
      return (<div className="tw-flex tw-flex-col">
        <label className="tw-font-bold">{this.props.t('rebateToApply')}</label>{this.state.rebates.length !== 0 && <span onClick={() => this.setState({ coupon: false })}className="tw-underline tw-cursor-pointer tw-text-blue-500 tw-text-sm">{this.props.t('usePromo')}</span>}
        <input className="tw-text-black tw-w-1/3 tw-h-10 tw-px-4" onChange={(e) => { this.handleCouponChange(e); }} value={this.state.couponValue} name="coupon" />
      </div>);
    }
  }

  getModeChange (item) {
    var product = item;
    return (
      <div>
        <div className="tw-flex tw-flex-row">
          <span className="tw-font-bold">{this.props.t('acquisition-method')}:</span>
          <div>
            {product.delivery === 'true' &&
            <label className="tw-inline-flex tw-items-center tw-ml-6">
              <input defaultChecked={getMode(product.id) === 'delivery'} onChange={() => {
                changeMode(product.id, 'delivery'); this.forceUpdate();
              }} type="radio" name={ 'mode' + item.id } value="delivery"></input>
              <span className="tw-ml-1">{ this.props.t('delivery') }</span>
            </label>}
            {product.pickup === 'true' &&
            <label className="tw-inline-flex tw-items-center tw-ml-6">
              <input defaultChecked={getMode(product.id) === 'pickup'} onChange={() => {
                changeMode(product.id, 'pickup'); this.forceUpdate();
              }} type="radio" name={ 'mode' + item.id } value="pickup"></input>
              <span className="tw-ml-1">{ this.props.t('pickup') }</span>
            </label>}
          </div>
        </div>
        <p>{getMode(product.id) === 'delivery' && this.getDeliveryFee(item)}</p>
      </div>
    );
  }

  getModeChangeVariant (item) {
    var product = item;
    return (
      <div>
        <div className="tw-flex tw-flex-row">
          <span className="tw-font-bold">{this.props.t('acquisition-method')}:</span>
          <div>
            {product.delivery === 'true' &&
            <label className="tw-inline-flex tw-items-center tw-ml-6">
              <input defaultChecked={getModeVariant(product.variant_id) === 'delivery'} onChange={() => {
                changeModeVariant(product.variant_id, 'delivery'); this.forceUpdate();
              }} type="radio" name={ 'modeV' + item.variant_id } value="delivery"></input>
              <span className="tw-ml-1">{ this.props.t('delivery') }</span>
            </label>}
            {product.pickup === 'true' &&
            <label className="tw-inline-flex tw-items-center tw-ml-6">
              <input defaultChecked={getModeVariant(product.variant_id) === 'pickup'} onChange={() => {
                changeMode(product.variant_id, 'pickup'); this.forceUpdate();
              }} type="radio" name={ 'modeV' + item.variant_id } value="pickup"></input>
              <span className="tw-ml-1">{ this.props.t('pickup') }</span>
            </label>}
          </div>
        </div>
        <p>{getModeVariant(product.variant_id) === 'delivery' && this.getDeliveryFee(item)}</p>
      </div>
    );
  }

  render () {
    const { t } = this.props;
    return (
      <Screen overall={true} type="triangle" loading={this.state.loading} cartSize={getAmountInCart()} title={this.props.t('pages:cart')} showCart={true}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 tw-h-64 mx-auto">
              <div className="tw-card">
                <h2 className="tw-text-xl tw-font-bold">{t('pages:cart')}</h2>
                {this.getRemoved()}
                {this.getAdjusted()}
                {this.cartHasItems() === true ? this.getProducts().map(item => {
                  if ((item.variant == false || item?.variant === undefined) && getQuantityFromCart(item.id) === 0) {
                    return null;
                  }
                  if (item.variant == true && getQuantityFromCartVariant(item.variant_id) === 0) {
                    return null;
                  }
                  return (
                    <>
                      <div key={item.id} className="tw-flex tw-flex-row tw-justify-between tw-mt-2">
                        <div className="tw-flex tw-flex-row">
                          <div style={{ height: 100, width: 100 }} className="tw-bg-center tw-bg-cover tw-cursor-pointer" onClick={() => { this.props.history.push('/products/' + item.id); }}>
                            <img src={(process.env.REACT_APP_STORAGE_URL + item.thumbnail_path).replace(/([^:]\/)\/+/g, '$1')} className="tw-w-full tw-h-full tw-object-cover"></img>
                          </div>
                          <div className="tw-p-2 tw-py-1">
                            <p><span className="hover:tw-underline tw-text-lg tw-cursor-pointer" onClick={() => { this.props.history.push('/products/' + item.id); }}>{item.title}</span> {this.props.t('of')} <span className="hover:tw-underline tw-text-lg tw-cursor-pointer" onClick={() => { this.props.history.push('/shop/' + item.employer.id); }}>{item.employer.name}</span></p>

                            <div style={{ width: 75, height: 30, backgroundColor: 'black', color: 'white' }} className="tw-border  tw-flex tw-flex-row tw-font-bold">
                              <div className="tw-cursor-pointer tw-border-r tw-flex tw-flex-row tw-items-center tw-justify-center" style={{ width: 25, height: 30 }} onClick={() => {
                                (item.variant == true) ? removeFromCartVariant(item.variant_id) : removeFromCart(item.id);
                                this.forceUpdate();
                              }}>-</div>
                              <div className="tw-font-normal tw-flex tw-flex-row tw-items-center tw-justify-center" style={{ width: 25, height: 30 }}><span>{
                                (item.variant == true) ? getQuantityFromCartVariant(item.variant_id) : getQuantityFromCart(item.id)
                              }
                              </span></div>
                              <div className="tw-cursor-pointer tw-border-l tw-flex tw-flex-row tw-items-center tw-justify-center" style={{ width: 25, height: 30 }} onClick={() => {
                                (item.variant == true) ? addToCartVariant(item.variant_id) : addToCart(item.id);
                                this.forceUpdate();
                              }}>+</div>
                            </div>
                            {item?.quantity && <span>Max: { item.quantity }</span>}
                            {(item.variant == true) ? this.getModeChangeVariant(item) : this.getModeChange(item)}
                          </div>
                        </div>
                        <div className="tw-p-2">
                          <span>{parseFloat(item.price).toFixed(2)} $</span>
                        </div>
                      </div>

                    </>

                  );
                })

                  : <div>
                    {this.props.t('cartEmpty')}
                  </div>}
                {this.getFreeDeliveries()}
                {this.getRebates()}
                {this.cartHasItems() === true
                  ? <>
                    <div className="tw-flex tw-flex-row tw-justify-end">
                      <span className="tw-font-semibold tw-text-lg">{ this.props.t('subTotal')}: {this.getTotal().toFixed(2)} $</span>
                    </div>
                    <button onClick={(e) => { this.initCheckout(e); }} className="tw-ml-auto focus:tw-bg-yellow-700 tw-bg-aa-yellow tw-px-4 tw-py-4 tw-font-bold  tw-text-sm tw-m-1 tw-self-end tw-leading-none tw-min-w-32 tw-text-black tw-cursor-pointer tw-flex tw-flex-row focus:tw-outline-none tw-items-center">
                      {this.props.t('checkout')}
                    </button>
                    <p className="tw-text-sm">{this.props.t('variantsCheckout')}</p>
                  </> : ''}
              </div>
            </div>
          </div>
        </div>
      </Screen>
    );
  }
}

Cart.propTypes = {
  t: PropTypes.func.isRequired
};

export default withRouter(withTranslation('common')(Cart));
