import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Screen from 'components/templates/Screen.js';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

export class Terms extends Component {
  render () {
    const { t } = this.props;
    return (
      <Screen type="triangle" title={this.props.t('pages:terms')}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 tw-h-64 mx-auto">
              <div className="tw-card">
                <h2 className="tw-text-xl tw-font-bold">{t('pages:terms')}</h2>
                <a onClick={() => { window.location.href = 'https://api.alpeconception.com/privacy-policy'; }} className="tw-text-blue-300 tw-cursor-pointer hover:tw-underline">{t('pages:terms')}</a>
              </div>
            </div>
          </div>
        </div>
      </Screen>
    );
  }
}

Terms.propTypes = {
  t: PropTypes.func.isRequired
};

export default withRouter(withTranslation('common')(Terms));
