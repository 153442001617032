import { HANDLE_CHANGE, CLEAR_FORM, LIST_ADD, LIST_EDIT, LIST_REMOVE, LIST_DESC_ADD, LIST_DESC_EDIT, LIST_CURRENT, LIST_DESC_CURRENT } from '../actionTypes';
import produce from 'immer';
import { getObjectId } from 'utils';

const input = (state = { listId: 0 }, action) => {
  switch (action.type) {
    case HANDLE_CHANGE: {
      const { name, value, form } = action.payload;
      if (!state[form]) {
        return produce(state, draft => {
          draft[form] = {};
          draft[form][name] = value;
        });
      } else {
        return produce(state, draft => {
          draft[form][name] = value;
        });
      }
    }
    case LIST_CURRENT: {
      const { name, value, form } = action.payload;
      if (!state[form]) {
        return produce(state, draft => {
          draft[form] = {};
          draft[form][name + '_current'] = value;
        });
      } else {
        return produce(state, draft => {
          draft[form][name + '_current'] = value;
        });
      }
    }
    case LIST_ADD: {
      const { name, value, form } = action.payload;
      if (!state[form]) {
        return produce(state, draft => {
          draft[form] = {};
          draft[form][name] = [{ id: draft.listId, value: draft[form][name + '_current'] }];
          draft[form][name + '_current'] = '';

          // draft[form][name].map(o => {
          //   o.value = '';
          //   return o;
          // });
          draft.listId++;
        });
      } else {
        return produce(state, draft => {
          if (!draft[form][name]) {
            draft[form][name] = [];
          }
          draft[form][name].unshift({ id: draft.listId, value: draft[form][name + '_current'] });
          draft[form][name + '_current'] = '';
          // draft[form][name].map(o => {
          //   o.value = '';
          //   return o;
          // });
          draft.listId++;
        });
      }
    }
    case LIST_EDIT: {
      const { name, value, id, form } = action.payload;
      return produce(state, draft => {
        if(id == 999) {
          if (!state[form]) {
            return produce(state, draft => {
              draft[form] = {};
              draft[form][name] = [{ id, value }];
            });
          } else if(!draft[form][name] || !getObjectId(draft[form][name], 999)) {
            if (!draft[form][name]) {
              draft[form][name] = [];
              draft[form][name].push({ id, value });
            }
          }
        }

  
        draft[form][name] = draft[form][name].map(item => {
          if (item.id === id) {
            item.value = value;
          }
          return item;
        });
      });
    }
    case LIST_REMOVE: {
      const { name, id, form } = action.payload;
      return produce(state, draft => {
        draft[form][name] = draft[form][name].filter(item => item.id !== id);
      });
    }
    case LIST_DESC_CURRENT: {
      const { name, value, training, form } = action.payload;
      if (!state[form]) {
        return produce(state, draft => {
          draft[form] = {};
          draft[form][name + '_current'] = {training, value};
        });
      } else {
        return produce(state, draft => {
          draft[form][name + '_current'] = {training, value};
        });
      }
    }
    case LIST_DESC_ADD: {
      const { name, value, training, form } = action.payload;
      if (!state[form]) {
        return produce(state, draft => {
          draft[form] = {};
          draft[form][name] = [{ id: draft.listId, training: training, value: value }];
          draft[form][name + '_current'] = null;
          draft.listId++;
        });
      } else {
        return produce(state, draft => {
          if (!draft[form][name]) {
            draft[form][name] = [];
          }
          draft[form][name].unshift({ id: draft.listId, training: training, value: value });
          draft[form][name + '_current'] = null;
          draft.listId++;
        });
      }
    }
    case LIST_DESC_EDIT: {
      const { name, value, training, id, form } = action.payload;
      return produce(state, draft => {
        draft[form][name] = draft[form][name].map(item => {
          if (item.id === id) {
            item.value = value;
            item.training = training;
          }
          return item;
        });
      });
    }
    case CLEAR_FORM: {
      const { form } = action.payload;
      return produce(state, draft => {
        delete draft[form];
      });
    }
    default:
      return state;
  }
};

export default input;
