import { Form } from 'components/forms/Form';
import Panel from 'components/forms/Panel';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import instance from 'axiosInstance';
import * as Cookies from 'js-cookie';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { addErrors } from 'redux/actions';
import {
  formToFormData,
  findIndex,
  successNotification,
  utilsClosePopup,
} from 'utils';
import ReactLoading from 'react-loading';

export class SetupPopup extends Component {


  constructor(props) {
    super(props);
    this.state = {
      currentPanel: 0,
      data: [],
      loading: true,
      display: true
    };
    switch(this.props.type) {
      case 'noAuthAffiliate':
        this.panels = [
          {
            'name': 'noAuthAffiliate',
            'type': 'noAuthAffiliate'
          }
        ];
        break;
      case 'affiliate':
        this.panels = [
          [{
            'name': 'affiliation',
            'type': 'description'
          },
          {
            'name': 'code',
            'required': true
          }],
        ];
        break;
      case 'onboarding':
        this.panels = [
          {
            name: 'onboarding',
            type: 'onboarding'
          }
        ]
        break;
      case 'explanations':
        this.panels = [
          {
            name: 'explanations',
            type: 'explanations'
          }
        ]
        break;
    }
    this.panels = this.panels.concat({name: 'submit', type: 'submit'});
    this.nextSlide = this.nextSlide.bind(this);
    this.previousSlide = this.previousSlide.bind(this);
    this.setPath = this.setPath.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    this.setState({
      loading: true
    });
    if (this.props.form) {
      if(this.props.type == 'affiliate') {
        var url = '/aff/signup';
        var fd = formToFormData([ 
          'code'
        ], this.props.form);

      }
      instance.post(url, fd)
        .then(s => {
          this.setState({
            display: false
          });
          successNotification(this.props.t('common:modificationsSaved'))
          if(this.props.type == 'affiliate') {
            Cookies.set('affiliated', true);
            this.props.history.push('/affiliation');
          }
        })
        .catch((error) => {
          this.checkErrorsStage(error.response.data.errors);
          this.props.addErrors(error.response.data.errors, 'popup');
        }).then(() => {
          this.setState({
            loading: false
          });
        });
    } else {
      this.setState({
        loading: false,
        currentPanel: 0
      });
    }
  }

  checkErrorsStage(errors) {
    var smallest = 999999;
    if (typeof errors === 'object') {
      Object.keys(errors).forEach(error => {
        var index = findIndex(this.panels, error);
        if (index < smallest) {
          smallest = index;
        }
      });
    }
    if (smallest !== 999999) {
      this.setState({
        currentPanel: smallest
      });
    }
  }

  componentDidMount() {
    switch(this.props.type) {
      case 'onboarding':
        instance.get('/candidate/fill-status').then((success) => {
          if(success.data.videoCvDone && success.data.cvDone) {
            utilsClosePopup('onboarding');
          } else {
            this.setState({
              loading: false,
              data: {
                cvDone: success.data.cvDone,
                videoCvDone: success.data.videoCvDone
              }
            });
          }
        });
        Cookies.set('onboardingSeen', true)
        break;
      case 'affiliate':
        this.setState({loading: false});
        break;
      default:
        instance.get('/candidate-data').then((success) => {
          this.setState({
            loading: false,
            data: {
              interests: success.data.interests,
              fieldsOfWork: success.data.fieldsOfWork,
              salary: success.data.salary,
              availability: success.data.availability,
              job_type: success.data.job_type,
              trainings: success.data.trainings,
              needs: success.data.needs,
              type: success.data.type,
            }
          });
        });
  
    }
  }


  nextSlide () {
    if(this.props.type == 'candidate' && this.props.form && this.props.form.cv && this.state.currentPanel == 1) {
      this.setState({currentPanel: this.state.currentPanel + 2});
    } else {
      this.setState({currentPanel: this.state.currentPanel + 1});
    }
  }

  setSlide(slide) {
    this.setState({currentPanel: slide})
  }

  previousSlide () {
    this.setState({currentPanel: this.state.currentPanel - 1});
  }

  setPath(path) {
    this.setState({path: path, currentPanel: this.state.currentPanel + 1});

  }


  render () {
    if(this.state.loading == false) {
      return (
        <div style={{ color: 'darkgray', minHeight: '40vh', minWidth: '50vw', position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.7)', zIndex: 10000 }} className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-inset-0 tw-text-gray-900">
          <Form name="popup" classes="md:tw-w-1/3 tw-w-11/12" classesOverwrite={true} onSubmit={(e) => {e.preventDefault()}}>
            <Panel popup={true} submit={this.handleSubmit} data={this.state.data} setPath={this.setPath} path={this.state.path} nextSlide={this.nextSlide} previousSlide={this.previousSlide} setSlide={this.setSlide} current={this.state.currentPanel} maxSlides={this.panels.length} panel={this.panels[this.state.currentPanel]} steps={this.steps} type={this.props.type} close={this.props.close} onlyShow={this.props.onlyShow} popupState={this.state} />
          </Form>
        </div>
      )    
    }
    if(this.state.loading == true) {
      return (
        <div style={{ minHeight: '40vh', minWidth: '50vw', position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.7)', zIndex: 10000 }} className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-inset-0">
          <ReactLoading type="bubbles" color="#f5ff00" />
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = ({ input, popup }) => {
  return {
    form: input.popup,
    step: popup.step
  };
};


export default withRouter(connect(mapStateToProps, { addErrors })(withTranslation(['pages'])(SetupPopup)));
