import React, { Component } from 'react';
import arrow from 'img/icons/arrow.svg';
import arrowLeft from 'img/icons/arrowLeft.svg';
import { withRouter } from 'react-router-dom';

export class Direction extends Component {
  getFlexClasses () {
    var classes = 'tw-flex tw-items-center';
    if (this.props.side === 'r') {
      return classes + ' tw-justify-end tw-flex-row';
    } else {
      return classes + ' tw-justify-end tw-flex-row-reverse';
    }
  }

  getArrow () {
    if (this.props.side === 'r') {
      return <img src={arrow} alt="Right arrow" className="h-32" />;
    }
    return <img alt="Left arrow" src={arrowLeft} className="h-32" />;
  }

  render () {
    return (
      <div className="tw-card tw-cursor-pointer" onClick={() => {
        if (this.props.to.startsWith('/')) {
          this.props.history.push(this.props.to);
        } else {
          window.location.href = this.props.to;
          return null;
        }
      }}>
        <div className={this.getFlexClasses()}>
          <p className="tw-font-bold tw-text-2xl tw-mx-4">{this.props.title}</p>
          {this.getArrow()}
        </div>
      </div>
    );
  }
}

export default withRouter(Direction);
