import React from 'react';
import { Helmet } from 'react-helmet';
import SendApplication from 'pages/forms/SendApplication';
import { withTranslation } from 'react-i18next';
import MyProfile from 'pages/profile/MyProfile';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from 'pages/forms/Login';
import Settings from 'pages/misc/Settings';
import Screen from 'components/templates/Screen';
import VerifyEmail from 'pages/profile/VerifyEmail';
import EmailVerified from 'pages/profile/EmailVerified';
import ModifyProfile from 'pages/profile/ModifyProfile';
import PrivateRoute from './PrivateRoute';
import Terms from 'pages/misc/Terms';
import Logout from 'pages/misc/Logout';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import NewEmailNotification from './pages/newEmail/NewEmailNotification';
import NewEmailVerified from './pages/newEmail/NewEmailVerified';
import ComingSoon from './pages/misc/ComingSoon';
import JobOffers from 'pages/jobOffers/JobOffers';
import PropTypes from 'prop-types';
import Homepage from './pages/Homepage';
import LogoutPage from 'pages/misc/LogoutPage';
import JobOffer from 'pages/jobOffers/JobOffer';
import ProductPage from 'pages/shop/ProductPage';
import Shop from 'pages/shop/Shop';
import Cart from 'pages/shop/Cart';
import OrderSuccess from 'pages/shop/OrderSuccess';
import Policies from 'pages/shop/Policies';
import EmployerShop from './pages/shop/EmployerShop';
import ManagePictures from 'pages/profile/ManagePictures';
import ManageNetwork from 'pages/profile/ManageNetwork';
import PublicProfile from 'pages/profile/PublicProfile';
import Messaging from 'pages/profile/Messaging';
import Affiliation from 'pages/profile/Affiliation';
import CoffeeMachine from 'pages/coffeeMachine/CoffeeMachine';
import Post from 'pages/coffeeMachine/Post';
import CreatePost from 'pages/coffeeMachine/CreatePost';
import UpdatePost from 'pages/coffeeMachine/UpdatePost';
import Dashboard from 'pages/profile/Dashboard';
import SignupLoginPrompt from 'components/common/SignupLoginPrompt';
import GoogleAnalytics from './GoogleAnalytics';
import CoffeeMachineRoute from 'CoffeeMachineRoute';
import * as Cookies from 'js-cookie';
import Signup from 'pages/profile/Signup';
import SignupCV from 'pages/profile/SignupCV';
import SignupCVVideo from 'pages/profile/SignupCVVideo';
import PasswordSetup from 'pages/profile/PasswordSetup';
import instance from 'axiosInstance';
import ReactTooltip from 'react-tooltip';
import LangPrompt from 'components/common/LangPrompt';
import SeenJobOffers from './pages/jobOffers/SeenJobOffers';
function App (props) {
  var parser = new URLSearchParams(window.location.search)
  if(parser.get('invite') && Cookies.get('invite') === undefined) {
    Cookies.set('invite', parser.get('invite'), { expires: 30 });
  }
  if(parser.get('employer_invite') && Cookies.get('employer_invite') === undefined) {
    Cookies.set('employer_invite', parser.get('employer_invite'), { expires: 30 });
  }
  if(parser.get('ref')) {
    if(parser.get('ref') != Cookies.get('ref')) {
      var fd = new FormData;
      fd.append('ref', parser.get('ref'));
      instance.post('/aff/visit', fd);  
      Cookies.set('ref', parser.get('ref'), { expires: 30 });
    }
  }
  if(parser.get('lang')) {
    if(parser.get('lang') == 'fr') {
      props.i18n.changeLanguage('fr');
      window.localStorage.setItem('i18nextLng', 'fr');  
    } else {
      props.i18n.changeLanguage('en');
      window.localStorage.setItem('i18nextLng', 'en');  
    }
  }

  return (
    <div className="tw-font-display">
      <Helmet>
        <title>AVA</title>
        <meta name="description" content="Find best jobs near you work from home. Trouver meilleurs emplois près de chez vous en télétravail. AVA is ALPE Virtual Assistant built for business development. Recruitment automatization & Ecommerce automatization." />
      </Helmet>
      <ReactNotification />
      <ReactTooltip />
      <Router>
        <GoogleAnalytics />
        <SignupLoginPrompt />
        <LangPrompt />
        <Switch>
          <Route path="/screen">
            <Screen title="Test Screen" />
          </Route>
          <Route path="/cv" component={SignupCV} />
          <Route path="/video-cv" component={SignupCVVideo} />
          <Route path="/logout" component={Logout} />
          <Route path="/settings" component={Settings} />
          <Route path="/terms" component={Terms} />
          <Route path="/password-setup" component={PasswordSetup} />
          <Route path="/verify-new-email" component={NewEmailVerified} />
          <Route path="/new-email" component={NewEmailNotification} />
          <Route path="/logout-page" component={LogoutPage} />
          <PrivateRoute path="/profile/network" component={ManageNetwork} />
          <Route path="/profile/:id" component={PublicProfile} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/profile" component={MyProfile} />
          <PrivateRoute path="/modify-profile" component={ModifyProfile} />
          <PrivateRoute reverse={true} path={['/signup', '/candidate-signup']} component={Signup} />
          <PrivateRoute path="/verify-email" component={VerifyEmail} />
          <Route path="/email-verified" component={EmailVerified} />
          <PrivateRoute reverse={true} path="/login" component={Login} />
          <Route path="/coffee-machine" component={CoffeeMachine} />
          <Route path="/posts/create" component={CreatePost} />
          <Route path="/posts/:id/update" component={UpdatePost} />
          <Route path="/affiliation" component={Affiliation} />
          <Route path="/messaging" component={Messaging} />
          <Route component={SeenJobOffers} path="/seen-job-offers" />
          <Route component={JobOffer} path="/job-offer/:slug" />
          <Route component={JobOffer} path="/job-offre/:slug" />
          <Route component={EmployerShop} path="/shop/:id" />
          <Route component={Shop} path="/shop" />
          <Route component={Cart} path="/cart" />
          <Route component={ProductPage} path="/products/:id" />
          <Route component={Policies} path="/policy/:id" />
          <Route component={OrderSuccess} path="/order/success" />
          <PrivateRoute path="/job-offers" component={JobOffers} />
          <Route path="/companies" render={() => {
            window.location.href = 'https://employers.alpeconception.com/employers';
            return null;
          }} />
          <CoffeeMachineRoute />
        </Switch>
      </Router>
      <a style={{display: 'none'}} href="https://traffic-creator.com/?ref=137000">Best Automated Bot Traffic</a>
    </div>
  );
}

App.propTypes = {
  i18n: PropTypes.object.isRequired
};

export default withTranslation(['pages', 'common'])(App);
