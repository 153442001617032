import React, { Component } from 'react';
import Screen from 'components/templates/Screen';
import { withTranslation } from 'react-i18next';
import emailSent from 'img/emailSent.svg';
import instance from 'axiosInstance';
import * as Cookies from 'js-cookie';
import { withRouter } from 'react-router-dom';

export class VerifyEmail extends Component {
  constructor (props) {
    super(props);
    this.state = { emailResend: 'resendEmail' };
    this.resendEmail = this.resendEmail.bind(this);
    this.checkEmail = this.checkEmail.bind(this);
  }

  resendEmail () {
    instance.get('/email/resend').then((success) => {
      this.setState({ emailResend: 'emailResent' });
    });
  }

  checkEmail () {
    this.setState({ loading: true });
    instance.get('/email/check').then((success) => {
      Cookies.set('email_verified', success.data.email_verified);
      this.setState({ loading: false });
      if (success.data.email_verified === true) {
        this.props.history.push('/password-setup');
      }
    });
  }

  render () {
    return (
      <Screen type="triangle" loading={this.state.loading} containerClasses="tw-h-full tw-flex tw-flex-row tw-justify-center tw-items-center" title={this.props.t('pages:verifyEmail')}>
        <div style={{ width: '500px', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} className="tw-flex tw-flex-col tw-justify-center tw-space-y-4 tw-items-center tw-text-center tw-card tw-p-4">
          <img style={{ height: '200px' }} src={emailSent} />
          <p>{this.props.t('verifyEmail')}</p>
          <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
            <div onClick={this.checkEmail} className="tw-mr-1 tw-border-aa-yellow tw-border-4 tw-p-3 tw-py-1  tw-font-bold hover:tw-bg-aa-yellow tw-cursor-pointer">
              <p>{this.props.t('common:checkEmail')}</p>
            </div>
            <div onClick={this.resendEmail} className="tw-ml-1 tw-border-aa-yellow tw-border-4 tw-p-3 tw-py-1  tw-font-bold hover:tw-bg-aa-yellow tw-cursor-pointer">
              <p>{this.props.t(`common:${this.state.emailResend}`)}</p>
            </div>
          </div>
        </div>
      </Screen>
    );
  }
}

export default withRouter((withTranslation(['common', 'pages'])(VerifyEmail)));
