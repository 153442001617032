import CompanyBox from 'components/jobOffers/CompanyBox';
import JobBox from 'components/jobOffers/JobBox';
import React, { Component } from 'react';
import Screen from 'components/templates/Screen';
import { withTranslation } from 'react-i18next';
import instance from 'axiosInstance';
import { isAuth, utilsOpenPopup } from 'utils';
import SetupPopup from 'components/profile/SetupPopup';
import * as Cookies from 'js-cookie';
import { withRouter } from 'react-router-dom';
import OldJobOffers from 'components/jobOffers/OldJobOffers';
import { Helmet } from 'react-helmet';

export class JobOffer extends Component {
  constructor () {
    super();
    this.state = { loading: true, job: {}, componentsLoading: false, popupDisplay: false };
    this.sendInterest = this.sendInterest.bind(this);
  }

  componentDidMount () {
    instance.get('/my-jobs/' + this.props.match.params.slug).then((s) => {
      this.setState({ loading: false, job: s.data.job });
    });
  }

  sendInterest (interested) {
    if (isAuth()) {
      var fd = new FormData();
      fd.append('job', this.state.job.id);
      fd.append('interested', interested);
      this.setState({ componentsLoading: true });
      instance.post('/my-jobs/interest', fd).then((s) => {
        this.props.history.push('/job-offers');
      }).catch((e) => {
        if(e.response.data.action == 'complete-profile') {
          Cookies.set('popup-origin', window.location.pathname, { expires: 1 / 48 });
          utilsOpenPopup('onboarding');
          this.props.history.push('/');
        }
      });
    } else {
      this.props.history.push({
        pathname: '/login',
        state: { from: this.props.location.pathname }
      });
    }
  }

  render () {
    if (!(Object.keys(this.state.job).length === 0)) {
      const job = this.state.job;
      const { employer } = job;
      return (
        <>
        <Screen loading={this.state.loading} type="video" title={this.props.t('pages:jobs')} padding={!isAuth()}>
        <OldJobOffers />
          <div className="container">
            <div className="row">        
              <CompanyBox loading={this.state.componentsLoading} employer={employer}/>
              <JobBox loading={this.state.componentsLoading} sendInterest={this.sendInterest} job={job} />
            </div>
          </div>
        </Screen>
        </>
      );
    }
    return (
      <Screen loading={this.state.loading} type="triangle" title={this.props.t('pages:jobs')}>
      </Screen>
    );
  }
}

export default withTranslation('common')(withRouter(JobOffer));
