import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import defaultThumbnail from 'img/defaultThumbnail.jpeg';
import { addToCart, getAmountInCart, getQuantityFromCart } from 'utils';
import { withTranslation } from 'react-i18next';
import ClampLines from 'react-clamp-lines';

export class ShopProduct extends Component {
  getImage () {
    const { product } = this.props;
    if (product.thumbnail_path !== 'default') {
      return `${(process.env.REACT_APP_STORAGE_URL + product.thumbnail_path).replace(/([^:]\/)\/+/g, '$1')}`;
    } else {
      return `${defaultThumbnail}`;
    }
  }

  getProductPrice (product) {
    if (product.rebate == null) {
      return parseFloat(product.price).toFixed(2);
    } else if (product.rebate.includes('$')) {
      var r = parseFloat(product.rebate.replace(/\D/g, ''));
      return <><span className="tw-line-through">{parseFloat(product.price).toFixed(2)}</span> <span>{parseFloat(product.price - r).toFixed(2)}</span></>;
    } else if (product.rebate.includes('%')) {
      var p = (100 - parseFloat(product.rebate.replace(/\D/g, ''))) / 100;
      return <><span className="tw-line-through">{parseFloat(product.price).toFixed(2)}</span> <span>{parseFloat(product.price * p).toFixed(2)}</span></>;
    }
  }

  render () {
    const { product } = this.props;
    return (
      <div className='shop-product tw-cursor-pointer tw-mb-5' onClick={(e) => { e.stopPropagation(); this.props.history.push('/products/' + product.id); }}>
        <div style={{ height: 240 }} className="tw-w-full tw-bg-center tw-bg-cover" ><img className="tw-object-cover tw-h-full tw-w-full" src={this.getImage()} /></div>
        <div style={{ background: 'rgb(67, 67, 73)' }} className="tw-text-white p-2">
          <ClampLines
            text={product.title}
            lines={1}
            buttons={false}
          />
          <p className="tw-font-semibold">{this.getProductPrice(product)} $</p>
          <div className="tw-flex-row tw-flex tw-items-center tw-flex-start tw-space-x-2">
            <button className="focus:tw-bg-yellow-700 tw-bg-aa-yellow tw-px-3 tw-py-2 tw-font-bold  tw-text-sm tw-m-1 tw-self-end tw-leading-none tw-min-w-32 tw-text-black tw-cursor-pointer tw-flex tw-flex-row focus:tw-outline-none" onClick={(e) => {
              e.stopPropagation();
              e.target.blur();
              if (product.subproducts == 0) {
                addToCart(product.id);
                this.props.cartUpdate(getAmountInCart());
                this.forceUpdate();
              } else {
                this.props.history.push('/products/' + product.id);
              }
            }}>{this.props.t('addToCart')}</button>
            {getQuantityFromCart(product.id) > 0 && <p className="tw-text-white">({getQuantityFromCart(product.id)})</p> }
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(withRouter(ShopProduct));
