import React, { Component } from 'react';
import Screen from 'components/templates/Screen.js';
import instance from 'axiosInstance';
import profile from 'img/placeholder/profile.svg';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import logo from 'img/icons/home.svg';
import Discussion from 'components/messages/Discussion';
import close from 'img/icons/close.svg';
import plus from 'img/icons/plus.svg';
import { Helmet } from 'react-helmet';

export class Messaging extends Component {
  constructor (props) {
    super(props);
    this.state = { loading: true, candidates: [], discussions: [], relationships: [], id: 0, currentDiscussion: 0, mode: 'discussions' };
    this.getCandidates = this.getCandidates.bind(this);
    this.getDiscussions = this.getDiscussions.bind(this);
    this.getClass = this.getClass.bind(this);
  }

  getCandidates () {
    return instance.get('/relationships-candidates').then(s => {
      this.setState({ candidates: s.data.candidates, id: s.data.id });
    });
  }

  getDiscussions () {
    return instance.get('/messages/discussions').then(s => {
      this.setState({ loading: false, discussions: s.data.discussions });
    });
  }

  getRelationships () {
    return instance.get('/relationships-candidates').then(s => {
      this.setState({ loading: false, candidates: s.data.candidates });
    });
  }

  componentDidMount () {
    this.getCandidates();
    this.getDiscussions();
    this.getRelationships();
    setInterval(() => this.getDiscussions(), 15000);
  }

  getProfilePic (pp) {
    if (!pp) {
      return profile;
    }
    return (process.env.REACT_APP_STORAGE_URL + pp).replace(/([^:]\/)\/+/g, '$1');
  }

  getClass (id) {
    var classes = 'tw-flex tw-flex-row tw-items-center tw-justify-between tw-p-3 hover:tw-bg-gray-800 tw-cursor-pointer bg-434349-darker ';
    if (this.state.currentDiscussion === id) {
      return classes + 'active-discussion';
    }
    return classes;
  }

  getLastMessage (lastM) {
    if (typeof lastM !== 'string') {
      lastM = lastM.message;
    }
    return lastM.length > 15 ? lastM.substr(0, 15) + '...' : lastM;
  }

  switchMode () {
    if (this.state.mode === 'discussions') {
      this.setState({ mode: 'new' });
    } else {
      this.setState({ mode: 'discussions' });
    }
  }

  startDiscussion (id) {
    this.setState({ loading: true, mode: 'discussions' });
    instance.get('/messages/discussions/new/' + id).then(() => {
      this.getDiscussions();
    });
  }

  getDiscussionsClasses () {
    if (this.state.currentDiscussion !== 0) {
      return 'tw-h-full tw-overflow-y-auto tw-border tw-border-r tw-border-l-0 tw-border-t-0 tw-border-b-0 md:tw-block tw-hidden';
    }
    return 'tw-h-full tw-overflow-y-auto tw-border tw-border-r tw-border-l-0 tw-border-t-0 tw-border-b-0 tw-w-full md:tw-w-auto';
  }

  render () {
    const { t } = this.props;
    const { discussions, mode, candidates } = this.state;
    return (
      <Screen type="triangle" padding={false} loading={this.state.loading} title={t('pages:messages')} containerClasses=" tw-py-0 bg-434349">
                  <Helmet>
        <title>AVA - {t('pages:messages')}</title>
      </Helmet>
        <div className="tw-flex tw-flex-row tw-h-full">
          <div style={{ minWidth: 360, borderColor: '#434349' }} className={this.getDiscussionsClasses()}>
            <div className="tw-flex tw-justify-between bg-434349 tw-p-3 tw-items-center">
              <span className="tw-text-xl tw-text-white tw-font-bold">{mode === 'new' ? 'Contacts' : 'Discussions'}</span>
              <img className="tw-cursor-pointer" onClick={() => { this.switchMode(); }} src={mode === 'new' ? close : 'https://s.svgbox.net/materialui.svg?fill=white&ic=send'} height="32" width="32" />
            </div>
            {mode === 'discussions' && discussions.map(d => {
              return (
                <div key={d.id} className={this.getClass(d.id)} onClick={() => { this.setState({ currentDiscussion: d.id }); }}>
                  <div className="tw-flex tw-flex-row">
                    <div className="tw-h-10 tw-w-10 ">
                      <img className="tw-h-full tw-w-full " src={this.getProfilePic(d.other.pp)} />
                    </div>
                    <div className="tw-ml-2 tw-flex tw-flex-col tw-text-white tw-justify-center">
                      <span>{d.other.name}</span>
                      <span className="tw-text-sm">{d['last-message-sender'].name + ': ' + (this.getLastMessage(d['last-message']))}</span>
                    </div>
                  </div>
                  {(d.seen === false && this.state.currentDiscussion !== d.id) && <div style={{ height: 10, width: 10 }} className="tw-bg-aa-yellow " />}
                </div>
              );
            })}
            {mode === 'new' && candidates.map(d => {
              return (
                <div key={d.id} className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-p-3 hover:tw-bg-gray-800 tw-cursor-pointer bg-434349-darker" onClick={() => { this.startDiscussion(d.related_id); }}>
                  <div className="tw-flex tw-flex-row tw-text-center">
                    <div className="tw-h-10 tw-w-10 ">
                      <img className="tw-h-full tw-w-full " src={this.getProfilePic(d.pp)} />
                    </div>
                    <div className="tw-ml-2 tw-flex tw-flex-col tw-text-white tw-justify-center">
                      <span>{d.name}</span>
                    </div>
                  </div>
                  <img src={plus} className="tw-h-6 tw-w-6" />
                </div>
              );
            })}

          </div>
          {this.state.currentDiscussion == 0
            ? <div className="tw-hidden md:tw-flex tw-flex-col tw-h-full tw-w-full tw-justify-center tw-items-center">
              <img style={{ height: 100 }} src={logo} />
              <p className="tw-text-white tw-mt-4">{t('noDiscussions')}</p>
            </div>
            : <Discussion quitConvo={() => { this.setState({ currentDiscussion: 0 }); }} id={this.state.currentDiscussion}/>}
        </div>
      </Screen>
    );
  }
}

export default withRouter(withTranslation('common')(Messaging));
