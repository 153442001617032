import React, { Component } from 'react';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { Helmet } from 'react-helmet';

export class PostMedias extends Component {
  constructor (props) {
    super(props);
    this.state = {
      value: 0
    };
    this.onChange = this.onChange.bind(this);
  }

  getYoutubeLink () {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = this.props.post.video_link.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
  }

  getYoutubeVideo () {
    if (this.props.post.video_link) {
      return <iframe width="560" height="315" src={'https://www.youtube.com/embed/' + this.getYoutubeLink()} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>;
    }
    return undefined;
  }

  getImagesArray () {
    var images = this.props.images;
    return images;
  }

  getImages () {
    const { post } = this.props;
    var images = this.getImagesArray();
    if (post.distant_url && post.distant_url.length !== 0) {
      console.log('asldsad');
      return [<img key={-1} style={{maxHeight: '70vh'}} src={post.distant_url} />].concat((images || []).map(image => (
        <img key={image.id} style={{maxHeight: '70vh'}} src={ (process.env.REACT_APP_STORAGE_URL + image.path).replace(/([^:]\/)\/+/g, '$1')} />
      )));
    }
    return (images || []).map(image => (
      <img key={image.id} style={{maxHeight: '70vh'}} src={ (process.env.REACT_APP_STORAGE_URL + image.path).replace(/([^:]\/)\/+/g, '$1')} />
    ));
  }

  getSlides () {
    if (this.getYoutubeVideo() !== undefined) {
      return [this.getYoutubeVideo()].concat(this.getImages());
    }
    return this.getImages();
  }

  onChange (value) {
    this.setState({ value });
  }

  getArrows () {
    if (this.getSlides().length === 1) {
      return false;
    } else {
      return true;
    }
  }

  render () {
    if (this.getYoutubeVideo() === undefined && this.props.images.length === 1) {
      return (
        <img src={(process.env.REACT_APP_STORAGE_URL + this.props.images[0].path).replace(/([^:]\/)\/+/g, '$1')} className="tw-mx-auto" style={{maxHeight: '70vh'}}/>
      );
    }
    if (this.props.images.length !== 0 || this.getYoutubeVideo() !== undefined || (this.props.post.distant_url && this.props.post.distant_url.length !== 0)) {
      return (
        <div>
          {(this.props.images.length !== 0 ? <Helmet>
            <meta property="og:image" content={ process.env.REACT_APP_STORAGE_URL + this.getImagesArray()[0].path } />
          </Helmet> : null)}
          <Carousel
            value={this.state.value}
            onChange={this.onChange}
            arrows={this.getArrows()}
            dots={true}
            slides={
              this.getSlides()
            }
          >
          </Carousel>
          <Dots
            value={this.state.value}
            onChange={this.onChange}
          />
        </div>
      );
    }
    return null;
  }
}

export default PostMedias;
