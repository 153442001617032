/* eslint no-unused-vars: 0 */
import React, { Component } from 'react';
import * as Cookies from 'js-cookie';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import CoffeeMachine from 'pages/coffeeMachine/CoffeeMachine';
import {withRouter} from 'react-router-dom';
import { isAuth, utilsOpenPopup } from 'utils';

export function CoffeeMachineRoute (props) {
  
  if(isAuth() || window.location.search.startsWith('?popup=signup') || window.location.search.startsWith('?popup=video')) {
    Cookies.set('welcomeSeen', true);
  }
  if (Cookies.get('welcomeSeen') === undefined && !isAuth()) {
    return <Redirect
      to={{ pathname: '/signup' }}
    />;
  }
  var parser = new URLSearchParams(window.location.search)
  if(parser.get('popup')) {
    Cookies.set('welcomeSeen', true);
    if(parser.get('popup') == 'signup' || parser.get('popup') == 'video') {
      var popup = parser.get('popup');
      if(popup == 'video') {
        utilsOpenPopup('video');
      } else if(popup == 'signup') {
        utilsOpenPopup('candidate');
      }
      if(!isAuth()) {
        Cookies.set('origin', window.location.pathname + window.location.search, { expires: 1 / 48 });
        props.history.push('/login');
      }
    }
  }


  return (
    <Route path="/" component={CoffeeMachine} />
  );
}

CoffeeMachineRoute.propTypes = {
  component: PropTypes.node.isRequired,
  reverse: PropTypes.bool
};

export default withRouter(CoffeeMachineRoute);
